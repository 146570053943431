import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, CheckboxWithLabel, ActionLink} from 'components';
import {ReviewRow} from 'components/flow';
import _ from 'lodash';
import {monthAndDay} from 'shared/tools/dates';
import {FormattedMessage} from 'react-intl';
import useModal from 'shared/hooks/useModal';
import EmailTemplatePreviewModal from 'components/modals/EmailTemplatePreviewModal';

const Review = observer(({uiState}) => {
  const emailPreviewModal = useModal(uiState, 'EmailPreviewModal');
  const {policy} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_off.policy.edit.Review')}
        description={t('time_off.policy.edit.Review your time off policy details to ensure everything is in order. Click on the sections to make changes.')}
      />
      <ReviewRow
        title='time_off.policy.edit.Policy Name'
        value={policy.name}
        editRoute='details'
      />
      <ReviewRow
        title='time_off.policy.edit.Work Week'
        value={t(
          'time_off.policy.edit.WORK_WEEK_SUMMARY',
          {
            hours: policy.workDayLengthHours,
            days: _.map(policy.workWeekDays, day => t(`time_off.policy.edit.days.${day}`)).join(', ')
          }
        )}
        editRoute='details'
      />
      <ReviewRow
        title='time_off.policy.edit.Policy Start Date'
        value={monthAndDay(policy.startMonth, policy.startDay)}
        editRoute='details'
      />
      {_.map(policy.typePolicies, typePolicy => <ReviewRow
        key={typePolicy.id}
        title={typePolicy.type.name}
        translateTitle={false}
        value={typePolicy.accrualFrequency === 'unlimited' ? '∞' : t('time_off.policy.edit.TYPE_POLICY_AMOUNT', {amount: typePolicy.baseAccrualRate})}
        editRoute={`time_off_rules/${typePolicy.id}`}
      />)}
      <ReviewRow
        title='time_off.policy.edit.Email'
        lastRow
        value={
          <div className='flex'>
            <CheckboxWithLabel
              label={'time_off.policy.edit.Introduction email to employees at launch'}
              checked={policy.sendEmployeeWelcomeNotifications}
              onChange={e => policy.sendEmployeeWelcomeNotifications = e.checked}
            />
            <ActionLink className='ml2' onClick={() => uiState.openEmailPreviewModal()}>
              <FormattedMessage id='time_off.policy.edit.preview'/>
            </ActionLink>
          </div>
        }
      />
      <EmailTemplatePreviewModal
        title='time_off.policy.edit.Welcome email to employees'
        isOpen={emailPreviewModal}
        onHide={() => uiState.closeEmailPreviewModal()}
        template={uiState.welcomeEmailPreview}
      />
    </div>
  );
});

export default Review;
