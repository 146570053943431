import React from 'react';
import {observer} from 'mobx-react';
import {QuickTip, FormLayout, DatePicker, ObjectSelect2, TextArea, CheckboxWithLabel, FormError, UploadFiles, AttachedDocumentDownload, LabelHelper} from 'components';
import CustomHours from './CustomHours';
import DelegateAssignments from './DelegateAssignments';
import {auth, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {calendarDate, dateFormatter} from 'shared/tools';
import _ from 'lodash';

const ExceededHoursList = observer(({exceededHours}) => {
  return (
    <ul className='pl2'>
      {exceededHours.map(day => {
        return <li key={day.date}>
          {calendarDate(day.date)}
        </li>;
      })}
    </ul>
  );
});

const Conflicts = observer(({uiState}) => {
  const {exceededHours} = uiState;

  if (exceededHours.length === 0) {
    return null;
  }
  return (
    <QuickTip
      trait='caution'
      header={<FormattedMessage id='employees.profile.time_off.request.CONFLICTING_HEADER'/>}
      paragraphs={[
        <FormattedMessage id='employees.profile.time_off.request.CONFLICTING_MESSAGE'/>,
        <ExceededHoursList exceededHours={exceededHours}/>
      ]}
    />
  );
});

const OnProbation = observer(({uiState}) => {
  const {employee, waitingPeriodFinalDay} = uiState;

  return (
    <QuickTip
      trait='caution'
      header={<FormattedMessage id='employees.profile.time_off.request.ON_PROBATION_HEADER'/>}
      paragraphs={[
        <FormattedMessage
          id='employees.profile.time_off.request.ON_PROBATION_MESSAGE'
          values={{name: employee.name, finalDay: dateFormatter(waitingPeriodFinalDay)}}
        />
      ]}
    />
  );
});

const NegativeBalances = observer(({uiState, showNegativeBalanceWarning}) => {
  const {employee, userIsManagerOrAdmin} = uiState;

  if (!showNegativeBalanceWarning) return null;

  return (
    <QuickTip
      trait='caution'
      header={<FormattedMessage id='employees.profile.time_off.request.NEGATIVE_BALANCES_HEADER'/>}
      paragraphs={[(userIsManagerOrAdmin && auth.employee.id !== employee.id) ?
        <FormattedMessage id='employees.profile.time_off.request.NEGATIVE_BALANCES_MANAGER_MESSAGE' values={{name: employee.name}}/> :
        <FormattedMessage id='employees.profile.time_off.request.NEGATIVE_BALANCES_MESSAGE'/>
      ]}
    />
  );
});

const BlockedDates = observer(({uiState}) => {
  const {employee, blockedDates, userIsManagerOrAdmin} = uiState;

  if (_.isEmpty(blockedDates)) return null;

  const paragraphs = blockedDates.map(blockedDate => (
    <div>
      <FormattedMessage
        id='employees.profile.time_off.request.BLOCKED_DATE'
        values={{
          name: blockedDate.name,
          dateRange: <span className='jumbo'>{blockedDate.dateRange}</span>
        }}
      />
    </div>
  ));
  paragraphs.push(
    userIsManagerOrAdmin ?
      <FormattedMessage id='employees.profile.time_off.request.BLOCKED_DATES_MANAGER_MESSAGE' values={{name: employee.name}}/> :
      <FormattedMessage id='employees.profile.time_off.request.BLOCKED_DATES_MESSAGE'/>
  );


  return (
    <QuickTip
      trait='caution'
      header={<FormattedMessage id='employees.profile.time_off.request.BLOCKED_DATES_HEADER'/>}
      paragraphs={paragraphs}
    />
  );
});

const RequestForm = observer(({uiState}) => {
  const {request, errors, canSubmitRequest, readOnly, showNegativeBalanceWarning} = uiState;

  return (
    <FormLayout>
      {uiState.showProbationWarning && <OnProbation uiState={uiState}/>}
      <ObjectSelect2
        items={uiState.sortedTypes}
        value={request.timeOffType}
        onChange={timeOffType => uiState.updateTimeOffType(timeOffType)}
        label='employees.profile.time_off.request.Time Off Type'
        disabled={!canSubmitRequest}
      />
      <Conflicts uiState={uiState}/>
      <BlockedDates uiState={uiState}/>
      <NegativeBalances uiState={uiState} showNegativeBalanceWarning={showNegativeBalanceWarning}/>
      <FormLayout.Group>
        <DatePicker
          label='employees.profile.time_off.request.Start Date'
          value={request.startDate}
          onChange={startDate => uiState.updateStartDate(startDate)}
          errorMessage={errors.startDate}
          disabled={!canSubmitRequest}
        />
        <DatePicker
          label='employees.profile.time_off.request.End Date'
          value={request.endDate}
          options={request.endDateDatePickerOptions}
          onChange={endDate => uiState.updateEndDate(endDate)}
          errorMessage={errors.endDate}
          disabled={!canSubmitRequest}
        />
      </FormLayout.Group>
      {!readOnly && <FormLayout>
          <CheckboxWithLabel
            label='employees.profile.time_off.request.Specify Custom Hours'
            onChange={() => uiState.toggleSpecifyCustomHours()}
            checked={uiState.specifyCustomHours}
            disabled={!canSubmitRequest}
          />
          <CustomHours uiState={uiState}/>
          <DelegateAssignments uiState={uiState}/>
          <TextArea
            label='employees.profile.time_off.request.Notes'
            value={request.notes}
            onChange={e => request.merge({notes: e.target.value})}
            placeholder='employees.profile.time_off.request.Enter request notes here'
            minRows={3}
            disabled={!canSubmitRequest}
          />
          <div>
            <LabelHelper label={t('employees.profile.time_off.request.Attachments')}/>
            {canSubmitRequest ?
              <UploadFiles
                defaultFiles={request.attachments}
                onChange={attachments => request.merge({attachments: attachments})}
                modelType='pto/time_off_request'
              /> :
              request.attachments.map(attachment => <AttachedDocumentDownload key={attachment.id} doc={attachment}/>)
            }
          </div>
        </FormLayout>
      }
      <FormError message={errors.base}/>
    </FormLayout>
  );
});

export default RequestForm;
