import {DomainStore} from 'shared/store';
import {action} from 'mobx';
import {t} from 'shared/core';

class PendingRequestsListState {
  store = new DomainStore();
  history;
  match;
  agent;
  statusOptions = [
    {
      id: 'all',
      display: t('time_off.requests.All')
    },
    {
      id: 'assigned_to_me',
      display: t('time_off.requests.Assigned to me')
    }
  ];

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action viewRequest(request) {
    this.history.push(`${this.match.path}/${request.id}`);
  }

  @action redirectToEdit(request) {
    window.location = `/employees/${request.employee.id}/time-off/request/${request.id}`;
  }

  @action async deleteRequest(request) {
    await this.store.destroy(request);
    await this.agent.refresh();
  }
}

export default PendingRequestsListState;
