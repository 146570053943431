import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import TurquoiseCheckmark from '../img/turquoise-checkmark@2X.png';
import HRKitIcon from '../img/hr-kit-icon@2x.png';
import PayrollIcon from '../img/payroll-icon@2x.png';

const PricingTableListItem = ({text}) => {
  return (
    <li className='mx3 ink-dark h3'>
      <img width="18px" className='mr2' src={TurquoiseCheckmark} alt='checkmark'/>
      <FormattedMessage id={text} />
    </li>
  );
};

const PricingTable = () => {
  return (
    <div className='mt1'>
      <div className='align-top py4 clearfix'>
        <div className='pricing-container-left col col-12 lg-col-6'>
          <div className='pricing-table br4'>
            <div className='pricing-table__header p3'>
              <span className='h1 block ink-dark'>
                <FormattedMessage id="company_settings.billing.pricing_table.Core" />
              </span>
              <div className='mt2'>
                <div className='pricing-table__tag'>
                  <img src={HRKitIcon} alt='hr-kit'/>
                  <span className='h3 ink-dark fw-m'>
                    <FormattedMessage id="company_settings.billing.pricing_table.HRIS" />
                  </span>
                </div>
              </div>
            </div>
            <ul className='pricing-table__list'>
              <PricingTableListItem text="company_settings.billing.pricing_table.Employee directory" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Employee self-serve" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Org charts" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Custom reports" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Company documents" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Digital documents" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Time-off tracking" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Performance reviews & check-ins" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Applicant tracking system" />
            </ul>
          </div>
        </div>

        <div className='pricing-container-right col col-12 lg-col-6'>
          <div className='pricing-table br4'>
            <div className='pricing-table__header p3'>
              <span className='h1 block ink-dark'>
                <FormattedMessage id="company_settings.billing.pricing_table.Complete" />
              </span>
              <div className='mt2'>
                <div className='pricing-table__tag'>
                  <img src={HRKitIcon} alt='hr-kit'/>
                  <span className='h3 ink-dark fw-m'>
                    <FormattedMessage id="company_settings.billing.pricing_table.HRIS" />
                  </span>
                </div>
                <span className='h1 ink-dark fw-m inline-block mx2 align-middle'>{'+'}</span>
                <div className='pricing-table__tag'>
                  <img src={PayrollIcon} alt='payroll'/>
                  <span className='h3 ink-dark fw-m'>
                    <FormattedMessage id="company_settings.billing.pricing_table.Payroll Services" />
                  </span>
                </div>
              </div>
            </div>
            <ul className='pricing-table__list'>
              <div className='blue-20 h3 fw-m mx3 mt3'>{"Everything in Core, plus:"}</div>
              <PricingTableListItem text="company_settings.billing.pricing_table.Dedicated payroll manager" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Automated payroll calculations and processing" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Taxable benefits and benefits deductions" />
              <PricingTableListItem text="company_settings.billing.pricing_table.CRA/WCB remittances" />
              <PricingTableListItem text="company_settings.billing.pricing_table.CPP, EI, WSIB calculations" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Year-end T4s, T4As, RL-1s" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Record of employment (ROEs)" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Custom payroll reports" />
              <PricingTableListItem text="company_settings.billing.pricing_table.Benefits sync" />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const LearnMore = () => {
  return (
    <div className='bg-white p2 sm-p3 box-shadow-2 rounded'>
      <div className='col-8 center mx-auto'>
        <div className='h1 mb2'>
          <FormattedMessage id="company_settings.billing.Simple pricing that scales with your business." />
        </div>
        <div className='h3 jumbo'>
          <FormattedMessage id="company_settings.billing.Manage HR like a pro while saving money, saving time and reducing risk. All at one simple price." />
        </div>
      </div>
      <PricingTable />
      <div className='my4 center'>
        <Link to='/billing/subscription/payment' className='Btn Btn--primary Btn--lg my2'>
          <FormattedMessage id="company_settings.billing.I'm ready"/>
        </Link>
        <div className='h4 jumbo'>
          <FormattedMessage id="company_settings.billing.Have questions?"/> <a href='https://www.collage.co/request' className='TextLink dodger'><FormattedMessage id="company_settings.billing.Talk to a pricing specialist."/></a>
        </div>
      </div>
      <div className='clearfix mxn2'>
        <div className='col col-12 sm-col-6 my3 px2'>
          <div className='h3 mb1 medium'>
            <FormattedMessage id="company_settings.billing.What happens when I terminate someone?"/>
          </div>
          <div className='h4 line-height-3 jumbo' style={{lineHeight: 2}}>
            <FormattedMessage id="company_settings.billing.When someone you’ve already paid for is terminated, we add a prorated credit to your account for the unused time. Credits are automatically applied to next month's bill."/>
          </div>
        </div>
        <div className='col col-12 sm-col-6 my3 px2'>
          <div className='h3 mb1 medium'>
            <FormattedMessage id="company_settings.billing.When will my card be charged?"/>
          </div>
          <div className='h4 line-height-3 jumbo' style={{lineHeight: 2}}>
            <FormattedMessage id="company_settings.billing.Your monthly billing cycle starts the day you launch Collage. At the start of each monthly billing cycle you pay for all active employees in Collage."/>
          </div>
        </div>
      </div>
      <div className='clearfix mxn2'>
        <div className='col col-12 sm-col-6 my3 px2'>
          <div className='h3 mb1 medium'>
            <FormattedMessage id="company_settings.billing.What happens when I add someone?"/>
          </div>
          <div className='h4 line-height-3 jumbo' style={{lineHeight: 2}}>
            <FormattedMessage id="company_settings.billing.You only get billed for active employees in Collage each month. When someone is added we prorate the charge for the used time in their first month."/>
          </div>
        </div>
        <div className='col col-12 sm-col-6 my3 px2'>
          <div className='h3 mb1 medium'>
            <FormattedMessage id="company_settings.billing.How do I cancel my subscription?"/>
          </div>
          <div className='h4 line-height-3 jumbo' style={{lineHeight: 2}}>
            <FormattedMessage id="company_settings.billing.Once you've set up your billing, you can cancel your subscription from within this page. Cancelling your subscription is immediate, and will disable access to Collage for all users, including yourself."/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
