import React from 'react';
import {NavLink, useRouteMatch} from 'react-router-dom';
import {t} from 'shared/core';
import {observer} from 'mobx-react';

const SideNavigationItem = observer(({name, path, disabled, current, translateName, isChild}) => {
  const {url} = useRouteMatch();
  const displayName = translateName ? t(name) : name;

  return (
    <li style={isChild ? {marginLeft: '1rem', marginTop: '0.5rem', marginBottom: '0.5rem'} : {}}>
      {disabled ?
        <span>{displayName}</span> :
        <div>
          <NavLink
            to={`${url}/${path}`}
            className={current ? 'current active' : 'complete'}
          >
            {displayName}
          </NavLink>
        </div>}
    </li>
  );
});

const SideNavigation = ({steps, currentStep = {index: -1}}) => {
  return (
    <ul className='Navigation-sidebar'>
      {steps.all().map(step => {
        if (!!step.subStepLocation && currentStep.location !== step.location) {
          return null;
        } else {
          return <SideNavigationItem
            disabled={step.index > currentStep.index}
            current={currentStep.location === step.location && (!step.subStepLocation || currentStep.subStepLocation === step.subStepLocation)}
            name={step.name}
            path={steps.path(step)}
            translateName={step.translateName}
            key={`${step.location}-${step.subStepLocation}`}
            isChild={!!step.subStepLocation}
          />;
        }
      })}
    </ul>
  );
};

SideNavigationItem.defaultProps = {
  translateName: true
};

export default SideNavigation;
