import React from 'react';
import {observer} from 'mobx-react';
import EmploymentRecord from './EmploymentRecord';
import TerminationRecord from './TerminationRecord';
import ReactivationRecord from './ReactivationRecord';
import OnLeaveRecord from './OnLeaveRecord';
import {Accordion, ActionLink, Tag} from 'components';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';
import {types, auth} from 'shared/core';

const PatchAndDeleteLinks = observer(({uiState, viewModel}) => {
  const {record} = viewModel;

  if (!viewModel.expanded) return null;
  if (!record.canDelete && !record.canPatch) return null;

  return (
    <div className='absolute right-0 top-0'>
      <a href='#' data-toggle='dropdown' className='submarine center'>
        <i className='material-icons'>{'more_horiz'}</i>
      </a>
      <ul className='dropdown-menu right-0'>
        <li>
          {record.canDelete && <ActionLink className='py1 px2 regular jumbo' onClick={() => uiState.showDeleteModal(record)}>
            <FormattedMessage id='employees.profile.role.Delete record'/>
          </ActionLink>}
        </li>
        <li>
          {record.canPatch && <ActionLink className='py1 px2 regular jumbo' onClick={() => uiState.openEditConfirmationModal(record)}>
            <FormattedMessage id='employees.profile.role.Make a correction'/>
          </ActionLink>}
        </li>
      </ul>
    </div>
  );
});

const CurrentTag = observer(({record}) => {
  if (!record.current) return null;

  return (
    <div className='table-cell align-top px2 inline-block'>
      <Tag><FormattedMessage id='employees.profile.role.current'/></Tag>
    </div>
  );
});

const TimelineItem = observer(({viewModel}) => {
  const {record} = viewModel;

  return (
    <div className='TimelineContainer'>
      <div className={`Timeline ${record.current
        ? 'active'
        : ''} ${viewModel.isLast
          ? 'last'
          : ''} ${viewModel.isFirst
            ? 'first'
            : ''}`}></div>
      </div>
  );
});

const ToggleExpandedLink = observer(({viewModel}) => {
  return (
    <ActionLink onClick={() => viewModel.toggleExpanded()}
                className={`h6 waterloo ${viewModel.record.current ? '' : 'ml2'}`}>
      <FormattedMessage id={viewModel.expandedText}/>
    </ActionLink>
  );
});

const EntryTitle = observer(({viewModel}) => {
  const {record} = viewModel;
  let translationKey;

  switch (record._type) {
    case types.EMPLOYMENT_RECORD:
      if (record.scheduled) {
        translationKey = 'SCHEDULED_DATE';
      } else if (viewModel.isLast && auth.moduleEnabled('directory')) {
        translationKey = 'STARTED_DATE';
      } else if (viewModel.isLast && !auth.moduleEnabled('directory')) {
        translationKey = 'ENROLLED_DATE';
      } else {
        translationKey = 'EFFECTIVE_DATE';
      }
      break;
    case types.ON_LEAVE_RECORD:
      translationKey = record.scheduled ? 'SCHEDULED_LEAVE' : 'EFFECTIVE_LEAVE';
      break;
    case types.TERMINATION_RECORD:
      translationKey = record.scheduled ? 'SCHEDULED_TERMINATION_DATE' : 'EFFECTIVE_TERMINATION_DATE';
      break;
    case types.REACTIVATION_RECORD:
      translationKey = record.scheduled ? 'SCHEDULED_DATE' : 'STARTED_DATE';
      break;
    default:
      throw new Error(`Timeline entry type ${record._type} is not supported.`);
  }

  return (
    <div className='table-cell inline-block'>
      <div className='h3 tuatara'>
        <FormattedMessage
          id={`employees.profile.role.${translationKey}`}
          values={{
            date: calendarDate(record.effectiveDate)
          }}
        />
      </div>
    </div>
  );
});

const EntryContent = observer(({uiState, viewModel}) => {
  switch (viewModel.record._type) {
    case types.EMPLOYMENT_RECORD:
      return <EmploymentRecord uiState={uiState} viewModel={viewModel}/>;
    case types.ON_LEAVE_RECORD:
      return <OnLeaveRecord uiState={uiState} viewModel={viewModel}/>;
    case types.TERMINATION_RECORD:
      return <TerminationRecord uiState={uiState} viewModel={viewModel}/>;
    case types.REACTIVATION_RECORD:
      return <ReactivationRecord uiState={uiState} viewModel={viewModel}/>;
    default:
      throw new Error(`Timeline entry type ${viewModel.record._type} is not supported.`);
  }
});

const JobTimelineEntry = observer(({uiState, viewModel}) => {
  const {record} = viewModel;
  return (
    <div className='flex'>
      <div className='col-1 relative align-top'>
        <TimelineItem viewModel={viewModel}/>
      </div>
      <div className={`col-11 py3 ${!viewModel.isLast ? 'border-bottom' : ''}`}>
        <div className='relative'>
          <div className='mr3'>
            <EntryTitle viewModel={viewModel}/>
            <CurrentTag record={record}/>
            <ToggleExpandedLink viewModel={viewModel}/>
          </div>
          <PatchAndDeleteLinks viewModel={viewModel} uiState={uiState}/>
        </div>
        <Accordion active={viewModel.expanded}>
          <EntryContent uiState={uiState} viewModel={viewModel}/>
        </Accordion>
      </div>
    </div>
  );
});

export default JobTimelineEntry;
