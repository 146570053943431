import React from 'react';
import {observer} from 'mobx-react';
import {
  Employees,
  Details,
  Review,
  TimeOffRules,
  TimeOffTypes
} from './components';

const Step = observer(({step, uiState}) => {
  const {policy} = uiState;

  switch (step) {
    case 'details':
      return <Details uiState={uiState}/>;
    case 'policy_types':
      return <TimeOffTypes uiState={uiState}/>;
    case 'employees':
      return <Employees uiState={uiState}/>;
    case 'time_off_rules':
      return <TimeOffRules uiState={uiState} typePolicy={policy.typePolicies}/>;
    case 'review':
      return <Review uiState={uiState}/>;
    default:
      throw new Error(`Location ${step} is not supported.`);
  }
});

export default Step;
