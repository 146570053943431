import React from 'react';
import {observer} from 'mobx-react';
import {t, endpoints, types} from 'shared/core';
import {Layout, InteractiveTable, Tag} from 'components';
import {PayPeriod} from 'stores/payroll';
import Subnav from '../../../Subnav';
import {FormattedMessage} from 'react-intl';

const PayNumber = observer(({model}) => {
  return (
    <div>
      <span className='pr1'>{model.payNumber}</span>
      {!!model.currentPeriod && <Tag colour='dodger'>
        <FormattedMessage id='payroll.periods.Current Period'/>
      </Tag>}
    </div>
  );
});

const COLUMNS = [
  {
    header: 'payroll.periods.Pay Number',
    component: PayNumber,
    width: 3
  },
  {
    header: 'payroll.periods.Pay Period',
    attribute: 'cycleRange',
    width: 4
  },
  {
    header: 'payroll.periods.Cutoff Date',
    attribute: 'calendarCutOffWarningDate',
    width: 2
  },
  {
    header: 'payroll.periods.Pay Date',
    attribute: 'calendarPayDate',
    width: 2
  }
];

const LOCALIZATION = {
  emptyState: 'payroll.periods.No pay periods for company'
};

const PayPeriodsContainer = observer(({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <Layout>
        <InteractiveTable
          title={t('payroll.periods.Pay Periods')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          searchable={false}
          showRoute='/updates/pay_period/:id'
          proxy={{
            type: 'async',
            modelType: types.PAY_PERIOD,
            model: PayPeriod,
            endpoint: endpoints.PAY_PERIODS
          }}
        />
      </Layout>
    </div>
  );
});

export default PayPeriodsContainer;
