import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import _ from 'lodash';

class EmployeeTableSelectorState {
  store = new DomainStore();
  onChange;

  @observable employees = [];
  @observable selectedEmployees = [];
  @observable availableEmployees = [];
  @observable errorMessage;

  @observable selectedAvailableEmployeeIds = [];
  @observable selectedEmployeeIds = [];

  receiveProps({onChange, employees, selectedEmployees, errorMessage}) {
    this.onChange = onChange;
    this.employees = employees || [];
    this.selectedEmployees = selectedEmployees || [];
    this.errorMessage = errorMessage;

    this.availableEmployees = _.differenceBy(this.employees, this.selectedEmployees, 'id');
  }

  @action addEmployees() {
    const employeesToAdd = _.filter(this.availableEmployees, (e) => _.includes(this.selectedAvailableEmployeeIds, e.id));
    this.selectedEmployees = _.unionBy(this.selectedEmployees, employeesToAdd, 'id');
    this.availableEmployees = _.reject(this.availableEmployees, (e) => _.includes(this.selectedAvailableEmployeeIds, e.id));
    this.selectedEmployeeIds = this.selectedAvailableEmployeeIds;
    this.selectedAvailableEmployeeIds = [];

    this.onChange(this.uniqueSelectedEmployees);
  }

  @action removeEmployees() {
    const employeesToRemove = _.filter(this.selectedEmployees, (e) => _.includes(this.selectedEmployeeIds, e.id));
    this.availableEmployees = _.unionBy(this.availableEmployees, employeesToRemove, 'id');
    this.selectedEmployees = _.reject(this.selectedEmployees, (e) => _.includes(this.selectedEmployeeIds, e.id));
    this.selectedAvailableEmployeeIds = this.selectedEmployeeIds;
    this.selectedEmployeeIds = [];

    this.onChange(this.uniqueSelectedEmployees);
  }

  @action addAllEmployees() {
    this.selectedEmployees = _.unionBy(this.selectedEmployees, this.availableEmployees, 'id');
    this.availableEmployees = [];
    this.selectedAvailableEmployeeIds = [];
    this.onChange(this.uniqueSelectedEmployees);
  }

  @action removeAllEmployees() {
    this.availableEmployees = _.unionBy(this.availableEmployees, this.selectedEmployees, 'id');
    this.selectedEmployees = [];
    this.onChange(this.uniqueSelectedEmployees);
  }

  @action updateSelectedAvailableEmployeeIds(selectedOptions) {
    this.selectedAvailableEmployeeIds = selectedOptions.map(option => option.value);
  }

  @action updateSelectedEmployeeIds(selectedOptions) {
    this.selectedEmployeeIds = selectedOptions.map(option => option.value);
  }

  @computed get uniqueSelectedEmployees() {
    return _.uniqBy(this.selectedEmployees, 'id');
  }

  @computed get canAddAll() {
    return this.availableEmployees.length > 0;
  }

  @computed get canRemoveAll() {
    return this.selectedEmployees.length > 0;
  }

  @computed get canAdd() {
    return this.selectedAvailableEmployeeIds.length > 0;
  }

  @computed get canRemove() {
    return this.selectedEmployeeIds.length > 0;
  }

  @computed get sortedAvailableEmployees() {
    return _.orderBy(this.availableEmployees, 'name');
  }

  @computed get sortedSelectedEmployees() {
    return _.orderBy(this.selectedEmployees, 'name');
  }
}

export default EmployeeTableSelectorState;
