import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Input, Button, FormLayout, Panel, Layout, CopyableField} from 'components';
import {observer} from 'mobx-react';
import JobSiteState from 'recruiting/state/JobSiteState';
import {auth, loader, t} from 'shared/core';
import FormError from 'components/FormError';
import defaultSocialImageEn from 'img/default-social-sharing-image-en.png';
import defaultSocialImageFr from 'img/default-social-sharing-image-fr.png';
import IndeedIntegrationPanel from './IndeedIntegrationPanel';

const SOCIAL_SHARING_IMG_HEIGHT = 314;
const SOCIAL_SHARING_IMG_WIDTH = 600;

const DefaultSocialImage = ({uiState}) => {
  return (
    <img
      className='block border--dashed mt2 mb1 cover'
      src={auth.locale === 'en' ? defaultSocialImageEn : defaultSocialImageFr}
      height={SOCIAL_SHARING_IMG_HEIGHT}
      width={SOCIAL_SHARING_IMG_WIDTH}
      alt={t('recruiting.job_site.Social Sharing Image')}
      role='presentation'
      onClick={() => uiState.openFileStack('socialSharingImage')}
    />
  );
};

const Image = observer(({uiState}) => {
  const {jobSite} = uiState;
  let imageUrl, onClick;

  if (jobSite.hasUploadedSocialSharingImage) {
    imageUrl = jobSite.socialSharingImage.uploadedUrl;
  } else {
    imageUrl = jobSite.socialSharingImageUrl;
    onClick = () => uiState.openFileStack();
  }

  return (
    <img
      className='block border--dashed mt2 mb1 cover'
      src={imageUrl}
      height={SOCIAL_SHARING_IMG_HEIGHT}
      width={SOCIAL_SHARING_IMG_WIDTH}
      alt={t('recruiting.job_site.Social Sharing Image')}
      role='presentation'
      onClick={onClick}/>
  );
});

const JobSiteForm = observer(({uiState}) => {
  const {errors, jobSite, widgetCodeSnippet} = uiState;

  return (
    <Layout>
      <Panel.Stack loose>
        <Panel>
          <Panel.Header title={t('recruiting.job_site.Job Site Settings')}/>
          <FormLayout>
            <Input
              placeholder='recruiting.job_site.EXAMPLE_URL'
              placeholderValues={{url: 'https://www.collage.co'}}
              label='recruiting.job_site.Company Website'
              errorMessage={errors.website}
              value={jobSite.website || ''}
              onChange={e => jobSite.website = e.target.value}
              className='large'
            />
            <Input
              value={jobSite.urlSlug || ''} errorMessage={errors.urlSlug}
              connectedLeft={
                <div className='InputGroup'>
                  <span className='InputGroup-tag large'>{"https://secure.collage.co/jobs/"}</span>
                </div>
              }
              label='recruiting.job_site.Job Site Address'
              onChange={e => jobSite.urlSlug = e.target.value} placeholder='recruiting.job_site.your-company-name'
              className='large'
            />
            <div className='mb3'>
              <FormattedMessage id='recruiting.job_site.Company Logo'/>
              <div className='h6 jumbo mb1'>
                <FormattedMessage id='recruiting.job_site.LOGO_NOTICE'
                  values={{
                    link: <a href='/company_settings/general' target='_blank'>
                            <FormattedMessage id='recruiting.job_site.Company Settings'/>
                          </a>
                  }}
                />
              </div>
            </div>
            <div>
              <FormattedMessage id='recruiting.job_site.Social Sharing Image'/>
              <div className='h6 jumbo mb2'><FormattedMessage id='recruiting.job_site.SOCIAL_SHARING_IMAGE_INSTRUCTIONS'/></div>
              {!jobSite.hasSocialSharingImage ?
                <DefaultSocialImage uiState={uiState}/>
                : <Image uiState={uiState}/>}
              {jobSite.hasSocialSharingImage && <FormError message={errors.base} />}
              <Button className='mt2 mr2' trait='default' size='sm' onClick={() => uiState.openFileStack('socialSharingImage')}>
                <FormattedMessage id='recruiting.job_site.Upload Social Sharing Image'/>
              </Button>
              {jobSite.hasSocialSharingImage && <Button className='mt2' trait='default' size='sm' onClick={() => uiState.clearSocialSharingImage()}>
                <FormattedMessage id='recruiting.job_site.Reset to Default'/>
              </Button>}
            </div>
          </FormLayout>
          <div className='mt3 right-align'>
            <Button trait='primary' onClick={() => uiState.saveForm()}>
              <FormattedMessage id='recruiting.job_site.Save'/>
            </Button>
          </div>
        </Panel>
        {jobSite.hasPermalink && <Panel>
          <Panel.Header
            title={t('recruiting.job_site.Job Site Integration Options')}
            description={t('recruiting.job_site.The options below will allow you to display a list of your jobs in Collage.')}
          />
          <div className='mt1'>
            <FormattedMessage id='recruiting.job_site.Option 1: Permalink'/>
            <div className='h6 jumbo mb1'>
              <FormattedMessage id='recruiting.job_site.This link can be used by candidates to view a list of all of your active jobs.'/>
            </div>
            <a href={jobSite.permalink} className='TextLink' target='_blank'>{jobSite.permalink}</a>
          </div>
          <div className='mt3'>
            <FormattedMessage id='recruiting.job_site.Option 2: Job Board Widget'/>
            <div className='h6 jumbo mb1'>
              <FormattedMessage id="recruiting.job_site.This option allows you to display a list of jobs on your company's site by copying and pasting the below code snippet."/>
              <a href={`https://support.collage.co/${auth.locale}/articles/7320862`} className='TextLink' target='_blank'>{t('recruiting.job_site.Learn more')}</a>
            </div>
            <CopyableField fieldValue={widgetCodeSnippet} multiline/>
          </div>
          <div className='mt3'>
            <FormattedMessage id='recruiting.job_site.Option 3: API Integration (Advanced)'/>
            <div className='h6 jumbo mb1'>
              <FormattedMessage id='recruiting.job_site.This option allows you to programmatically list jobs on your company site via our public API.'/>
              <a href='http://support.collage.co/en/articles/1568979-integrating-jobs-on-your-website' className='TextLink' target='_blank'>{t('recruiting.job_site.Learn more')}</a>
            </div>
            <a href={jobSite.integrationLink} className='TextLink' target='_blank'>{jobSite.integrationLink}</a>
          </div>
        </Panel>}
        <IndeedIntegrationPanel uiState={uiState}/>
      </Panel.Stack>
    </Layout>
  );
});

async function load() {
  const uiState = new JobSiteState();
  await uiState.load();
  return {uiState};
}

export default loader(JobSiteForm, load);
