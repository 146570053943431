import _ from 'lodash';

class Steps {
  __allSteps;

  constructor(allSteps) {
    this.__allSteps = allSteps;
  }

  all() {
    return this.__allSteps;
  }

  byIndex(index, traversingBack = false) {
    const step = this.__allSteps[index];

    if (step.noContent) {
      if (traversingBack) {
        return this.byIndex(index - 1, true);
      } else {
        return this.byIndex(index + 1);
      }
    } else {
      return step;
    }
  }

  first() {
    return _.head(this.__allSteps);
  }

  last() {
    return _.last(this.__allSteps);
  }

  before({location, subStepLocation}) {
    const searchBy = {location};

    if (subStepLocation) {
      searchBy.subStepLocation = subStepLocation;
    }

    const index = _.findIndex(this.__allSteps, searchBy);

    if (index <= 0) return null;

    return this.byIndex(index - 1, true);
  }

  after({location, subStepLocation}) {
    const searchBy = {location};

    if (subStepLocation) {
      searchBy.subStepLocation = subStepLocation;
    }

    const index = _.findIndex(this.__allSteps, searchBy);

    if (index === -1 || index === this.__allSteps.length - 1) return null;

    return this.byIndex(index + 1);
  }

  path(step) {
    if (step.noContent) {
      return this.path(this.after(step));
    } else if (step.subStepLocation) {
      return `${step.location}/${step.subStepLocation}`;
    } else {
      return step.location;
    }
  }
}

export default Steps;
