import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Input} from 'components';
import {Modal, ModalButtons} from 'components/modals';

const EditIndeedModal = observer(({modalOpen, uiState}) => {
  const {editingJobSite, errors} = uiState;

  return (
    <Modal
      title='recruiting.job_site.Edit Indeed Account Info'
      isOpen={modalOpen} 
      onHide={() => uiState.closeEditIndeedModal()}
    >
      <FormLayout>
        <Input
          label='recruiting.job_site.Indeed Company Name'
          value={editingJobSite.indeedCompanyName}
          errorMessage={errors.indeedCompanyName}
          onChange={e => editingJobSite.indeedCompanyName = e.target.value}
        />
        <Input
          label='recruiting.job_site.Indeed Account Email'
          value={editingJobSite.indeedAccountEmail}
          errorMessage={errors.indeedAccountEmail}
          onChange={e => editingJobSite.indeedAccountEmail = e.target.value}
        />
      </FormLayout>
      <ModalButtons onCancel={() => uiState.closeEditIndeedModal()} onSave={() => uiState.saveIndeedForm()}/>
    </Modal>
  );
});

export default EditIndeedModal;
