import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable, DropdownFilter, EmployeeNameWithAvatar, Button} from 'components';
import {AccountSummariesState} from '../state';
import {withState, types, endpoints, t} from 'shared/core';
import {TimeOffAccountSummary} from 'stores/time_off';
import BalanceAdjustmentModal from './BalanceAdjustmentModal';

const AdjustmentButton = ({model, uiState}) => {
  if (!model.hasLink('adjustment')) return null;

  return (
    <Button onClick={() => uiState.openBalanceAdjustmentModal(model)} className='Btn--icon' trait='default' hint={t('time_off.account_summaries.Adjust Balances')}>
      <i className='material-icons h2 waterloo'>{'exposure'}</i>
    </Button>
  );
};

const COLUMNS = [
  {
    header: 'time_off.account_summaries.Name',
    render: model => <EmployeeNameWithAvatar href={`/employees/${model.employee.id}/time-off`} employee={model.employee}/>,
    sortBy: 'name',
    width: 4
  },
  {
    header: 'time_off.account_summaries.Accrued',
    attribute: 'accruedInDays',
    sortable: false,
    width: 2
  },
  {
    header: 'time_off.account_summaries.Available',
    render: model => <span className={`${model.availableInDays < 0 ? 'scarlet' : ''}`}>{model.availableInDays}</span>,
    sortable: false,
    width: 2
  },
  {
    header: 'time_off.account_summaries.Used',
    attribute: 'usedInDays',
    sortable: false,
    width: 1
  },
  {
    header: 'time_off.account_summaries.Scheduled',
    attribute: 'scheduledInDays',
    sortable: false,
    width: 2
  },
  {
    component: AdjustmentButton,
    width: 1
  }
];

const LOCALIZATION = {
  emptyState: 'time_off.account_summaries.No employees to show'
};

const AccountSummariesWithStateContainer = observer(({uiState}) => {
  const {timeOffTypes} = uiState;

  return (
    <div className='Container'>
      <InteractiveTable
        uiState={uiState}
        columns={COLUMNS}
        localization={LOCALIZATION}
        showLinks={false}
        sortable={true}
        saveStateToUrl
        onMount={agent => uiState.setInteractiveAgent(agent)}
        proxy={{
          type: 'async',
          modelType: types.TIME_OFF.ACCOUNT_SUMMARY,
          model: TimeOffAccountSummary,
          endpoint: endpoints.TIME_OFF.ACCOUNT_SUMMARIES
        }}>
        <DropdownFilter options={timeOffTypes} attr='type'/>
      </InteractiveTable>
      <BalanceAdjustmentModal uiState={uiState}/>
    </div>
  );
});

export default withState(AccountSummariesWithStateContainer, AccountSummariesState);
