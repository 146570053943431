import React from 'react';
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";
import {auth} from 'shared/core';
import {HtmlRender} from 'components/index';

const EmailTemplatePreview = observer(({template}) => {
  const emailContent = auth.locale === 'en' ? template.emailContentEn : template.emailContentFr;
  return (
    <div>
      <div className="border px3 py2 bg-haze rounded h4">
        <div className="py1">
          <HtmlRender html={emailContent}/>
        </div>
      </div>
      <div className="mt2 jumbo">
        <FormattedMessage
          id="components.email_template_preview.edit_email_template_link"
          values={{
            link: <a href={`/company_settings/company_email_templates/${template.id}`} target={'_blank'}>
                    <FormattedMessage id="components.email_template_preview.click here"/>
                  </a>
          }}
        />
      </div>
    </div>
  );
});

export default EmailTemplatePreview;
