import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {User} from 'stores/users';
import TimeOffRequest from './TimeOffRequest';

class TimeOffTransaction extends DomainObject {
  @observable id;
  @observable postedAt;
  @observable transactionType;
  @observable createdByUser;
  @observable timeOffRequest;
  @observable amount;
  @observable notes;

  @action merge(other) {
    super.merge(other, {
      _dates: ['postedAt'],
      createdByUser: User,
      timeOffRequest: TimeOffRequest
    });
  }

  @computed get hasAmount() {
    return this.amount !== null;
  }
}

export default TimeOffTransaction;
