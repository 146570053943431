import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {TimeOffPageState} from './state';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {
  AccountSummariesContainer,
  HolidaysContainer,
  PendingRequestPage,
  PolicyDirectory,
  PolicyEditFlow,
  TypesContainer,
  BlockedDatesContainer
} from './components';

const TimeOffPage = observer(({uiState}) => {
  const {scope} = uiState;

  return (
    <Router basename='/time_off'>
      <Switch>
        <Route path={'/account_summaries'} render={() => <AccountSummariesContainer scope={scope} />} />
        <Route path={'/pending_requests'} render={({match}) => <PendingRequestPage match={match} scope={scope} />} />
        <Route path='/policies/:id/8c90d380-1419-4d23-9e04-3bbb64599381' component={PolicyEditFlow}/>
        <Route path={'/policies'} render={() => <PolicyDirectory scope={scope} />} />
        <Route path={'/types'} render={() => <TypesContainer scope={scope} />} />
        <Route path={'/holidays'} render={({match}) => <HolidaysContainer match={match} scope={scope} />} />
        <Route path={'/blocked_dates'} render={({match}) => <BlockedDatesContainer match={match} scope={scope} />} />
        <Redirect exact from='/' to={`${scope.accountSummaries ? '/account_summaries' : '/pending_requests'}`} />
      </Switch>
    </Router>
  );
});

export default withState(TimeOffPage, TimeOffPageState);
