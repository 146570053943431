import React from 'react';
import {observer} from 'mobx-react';
import sanitizeHtml from 'sanitize-html';

const isLexical = (html) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');

  // This check is necessary for demo data which sets the html values to plaintext, not html
  const firstElement = dom.activeElement.firstElementChild;
  if (firstElement == null) {
    return false;
  }

  return firstElement.className.startsWith('editor');
};

const HtmlRender = observer(({html, htmlTags, className, setRef}) => {
  const allowedTags = sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'u', 'a', 'span', 'img']);
  const sanitizationConfig = {
    allowedTags: allowedTags.concat(htmlTags),
    allowedClasses: {
      a: ['dodger']
    },
    allowedAttributes: {
      '*':['style', 'class'], a: ['href', 'target'], img: ['src', 'width', 'height'], li: ['value']
    },
    allowedSchemesByTag: { img: sanitizeHtml.defaults.allowedSchemes.concat(['data']) }
  };

  const sanitizedHtml = sanitizeHtml(html, sanitizationConfig);

  return (
    <div
      className={`HTMLRender word-break ${className} ${!isLexical(sanitizedHtml) && 'non-lexical'}`}
      ref={(element) => {
        if (setRef) {
          setRef(element);
        }
      }}
      dangerouslySetInnerHTML={{__html: html === null ? '' : sanitizedHtml}}/>
  );
});

export default HtmlRender;

// HTMLRender is used to uniformly render HTML from user input on the application
// Examples include: Publishing an announcement or sending a custom email to an ATS candidate
//
// props:
//
// - html: html string that needs to be rendered
//
// - htmlTags: array of whitelisted HTML tags that can be rendered
//   default values: [ 'p', 'em', 'strong', 'iframe' ] provided by sanitize-html library
