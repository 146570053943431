import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table} from 'components';
import {calendarDate} from 'shared/tools';
import {t} from 'shared/core';

const COLUMNS = [
  {
    header: 'employees.profile.time_off.request.forecast.Balance',
    attribute: 'type',
    width: 4
  },
  {
    header: 'employees.profile.time_off.request.forecast.Before',
    render: model => <span className={`${model.before_is_negative ? 'scarlet' : ''}`}>{model.before}</span>,
    width: 4
  },
  {
    header: 'employees.profile.time_off.request.forecast.After',
    render: model => <span className={`${model.after_is_negative ? 'scarlet' : ''}`}>{model.after}</span>,
    width: 4
  }
];

const Usage = observer(({uiState}) => {
  const {type, request, usageItems} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('time_off.requests.TYPE_USAGE', { type })} />
      <Panel.Subheader title={t('employees.profile.time_off.request.AS_OF', {date: calendarDate(request.startDate)})} />
      <Table
        showLinks={false}
        columns={COLUMNS}
        models={usageItems}
      />
    </Panel>
  );
});

export default Usage;
