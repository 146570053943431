import {DomainObject} from 'shared/store';
import {computed, observable, action} from 'mobx';
import {rangeDate} from 'shared/tools';
import Employee from 'stores/employees/Employee';

class PayPeriod extends DomainObject {
  @observable payNumber;
  @observable periodStartDate;
  @observable periodEndDate;
  @observable cutoffWarningDate;
  @observable payDate;
  @observable employees;
  @observable notes;
  @observable complete;
  @observable currentPeriod;

  @action merge(other) {
    super.merge(other, {
      _dates: [
        'periodStartDate',
        'periodEndDate',
        'cutoffWarningDate',
        'payDate'
      ],
      employees: [Employee]
    });
  }

  @computed get cycleRange() {
    return `${rangeDate(this.periodStartDate)} - ${rangeDate(this.periodEndDate)}`;
  }

  @computed get calendarCutOffWarningDate() {
    return rangeDate(this.cutoffWarningDate);
  }

  @computed get calendarPayDate() {
    return rangeDate(this.payDate);
  }
}

export default PayPeriod;
