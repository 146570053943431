import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import AccrualForm from './AccrualForm';
import CarryoverForm from './CarryoverForm';
import WaitingPeriodForm from './WaitingPeriodForm';
import ApprovalForm from './ApprovalForm';
import {Panel, RadioButtonGroup, PanelRadioButton} from 'components';


const TimeOffRules = observer(({uiState}) => {
  const {typePolicy, accrualType} = uiState;

  return (
    <Panel.Stack loose>
      <Panel>
        <Panel.Header title={t('time_off.policy.edit.ACCRUAL_SETTINGS', {type: typePolicy.type.name})}/>
        <RadioButtonGroup
          className={'clearfix table'}
          name='accrualType'
          selectedValue={accrualType}
          onChange={(name, value) => uiState.selectAccrualType(value)}
        >
          <PanelRadioButton
            value='standard'
            label='time_off.policy.edit.Standard'
            description='time_off.policy.edit.STANDARD_EXPLANATION'
            onClick={() => uiState.accrualType = 'standard'}
            isActive={accrualType === 'standard'}
          >
            <AccrualForm uiState={uiState}/>
            <CarryoverForm uiState={uiState}/>
          </PanelRadioButton>
          <PanelRadioButton
            value='unlimited'
            label='time_off.policy.edit.Unlimited'
            description='time_off.policy.edit.UNLIMITED_EXPLANATION'
            onClick={() => uiState.accrualType = 'unlimited'}
            isActive={accrualType === 'unlimited'}
          >
          </PanelRadioButton>
        </RadioButtonGroup>
      </Panel>
      <WaitingPeriodForm uiState={uiState}/>
      <ApprovalForm uiState={uiState}/>
    </Panel.Stack>
  );
});

export default TimeOffRules;
