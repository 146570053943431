import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, RadioButtonGroup, PanelRadioButton, ObjectSelect2} from 'components';

const ApprovalForm = observer(({uiState}) => {
  const {typePolicy, assignee, availableAssignees} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('time_off.policy.edit.APPROVALS', {type: typePolicy.type.name})}/>
      <RadioButtonGroup
        className={'clearfix table'}
        name='approvalType'
        selectedValue={typePolicy.approvalType}
        onChange={(name, value) => typePolicy.approvalType = value}
      >
        <PanelRadioButton
          value='single'
          label='time_off.policy.edit.approvals.Single approval'
          description='time_off.policy.edit.approvals.SINGLE_EXPLANATION'
        />
        <PanelRadioButton
          value='two_stage'
          label='time_off.policy.edit.approvals.Two-step approval'
          description='time_off.policy.edit.approvals.TWO_STAGE_EXPLANATION'
        >
          <ObjectSelect2
            value={assignee}
            onChange={assignee => uiState.selectAssignee(assignee)}
            searchable={true}
            items={availableAssignees}
          />
        </PanelRadioButton>
        <PanelRadioButton
          value='auto'
          label='time_off.policy.edit.approvals.Auto approval'
          description='time_off.policy.edit.approvals.AUTO_EXPLANATION'
        />
      </RadioButtonGroup>
    </Panel>
  );
});

export default ApprovalForm;
