import React from 'react';
import {observer} from 'mobx-react';
import {Panel, DownloadLink, HtmlRender} from 'components';
import {t} from 'shared/core';
import _ from 'lodash';

const BeneficiaryAssignment = observer(({uiState}) => {
  const {benefitInfo} = uiState;
  return (
    <Panel.Stack loose>
      {benefitInfo.beneficiaryForms.map(form => (
        <Panel>
          <Panel.Header title={t('employees.profile.benefits.BENEFICIARY_ASSIGNMENT_FOR_CARRIER', {carrier: form.name})}/>
          <div className='jumbo'>
            <HtmlRender html={form.instructions}/>
            {form.url && <div className='flex flex-col'>
              <div className={'mt1'}>
                <DownloadLink
                  href={form.url}
                  title={t('employees.profile.benefits.link_download_beneficiary_named', {name: form.name})}
                />
              </div>
            </div>}
          </div>
        </Panel>
      ))}
    </Panel.Stack>
  );
});

export default BeneficiaryAssignment;
