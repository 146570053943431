import React from 'react';
import EmployeeTimeOffState from '../state/EmployeeTimeOffState';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import TimeOffBalances from './TimeOffBalances';
import {FormattedMessage} from 'react-intl';
import {Button, Panel, ActionLink} from 'components';
import PolicySummaryModal from './PolicySummaryModal';
import UnlimitedPolicySummaryModal from './UnlimitedPolicySummaryModal';
import TransactionList from './TransactionList';
import RequestNotesModal from './RequestNotesModal';
import {TimeOffPolicySelector} from './time_off_policy_selector';
import {withRouter} from 'react-router-dom';
import UpcomingHolidaysModal from './UpcomingHolidaysModal';
import RequestList from './RequestList';

const EmployeeTimeOffContainerWithState = observer(({uiState, history}) => {
  return (
    <Panel.Stack loose>
      <Panel>
        <div className='PanelHeader flex justify-content-between'>
          <div>
            <div className='PanelTitle mb1'><FormattedMessage id='employees.profile.time_off.Time Off'/></div>
            <ActionLink onClick={() => uiState.openUpcomingHolidaysModal()}>
              <FormattedMessage id='employees.profile.time_off.View Holidays'/>
            </ActionLink>
          </div>
          <div className='flex align-items-start'>
            {uiState.showRequestTimeOffButton && <Button className='ml2' onClick={() => uiState.requestTimeOff()}>
              <FormattedMessage id='employees.profile.time_off.Request Time Off'/>
            </Button>}
            {uiState.showRecordTimeOffButton && <Button className='ml2' onClick={() => uiState.requestTimeOff()}>
              <FormattedMessage id='employees.profile.time_off.Request on behalf of'/>
            </Button>}
          </div>
        </div>
        <TimeOffBalances uiState={uiState}/>
        <TimeOffPolicySelector employee={uiState.employee} policy={uiState.policy}/>
      </Panel>
      <RequestList uiState={uiState}/>
      <TransactionList uiState={uiState}/>
      <RequestNotesModal uiState={uiState}/>
      <UpcomingHolidaysModal uiState={uiState} />
      <PolicySummaryModal uiState={uiState}/>
      <UnlimitedPolicySummaryModal uiState={uiState}/>
    </Panel.Stack>
  );
});

export default withRouter(withState(EmployeeTimeOffContainerWithState, EmployeeTimeOffState, {
  spinner: Panel.Spinner
}));
