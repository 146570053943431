import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {Steps} from 'shared/tools';
import {TimeOffPolicy, TimeOffTypePolicy, TimeOffType, BalanceAdjustmentPolicy, AnniversaryBonus} from 'stores/time_off';
import {initialSteps, finalSteps} from './steps';
import {Employee} from 'stores/employees';
import {User} from 'stores/users';
import _ from 'lodash';
import moment from 'moment';

class PolicyEditFlowState {
  store = new DomainStore();
  history;
  match;

  @observable policy;
  @observable typePolicy;
  @observable balanceAdjustmentPolicy;
  @observable accrualType = 'standard';
  @observable carryoverType;
  @observable assignee = {};
  @observable availableAssignees;
  @observable waitingPeriodEnabled = false;
  @observable steps;
  @observable errors = {};

  @observable hoursPerWorkday;
  @observable workDayLengthChanged = false;
  @observable startMonthDate;

  @observable timeOffTypes = [];
  @observable selectedTimeOffTypes = [];

  @observable availableEmployees = [];
  @observable selectedEmployees = [];
  @observable welcomeEmailPreview;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.POLICY.with(this.match.params.id),
      endpoints.USERS.ACTIVE
    ]);

    this.policy = new TimeOffPolicy(this.store._getSingle(types.TIME_OFF.POLICY));
    this.hoursPerWorkday = this.policy.workDayLength / 3600;
    this.startMonthDate = new Date(new Date().getFullYear(), 6, 1);
    this.steps = new Steps(this._initializeSteps());
  }

  @action async onStepChanged({location, subStepLocation}) {
    switch (location) {
      case 'policy_types':
        await this.initializeTimeOffTypes();
        break;
      case 'employees':
        await this.initializeEmployees();
        break;
      case 'time_off_rules':
        this.initializeTypePolicy(subStepLocation);
        break;
      case 'review':
        await this.initializeEmailTemplate();
        break;
      default:
        break;
    }
  }

  @action async initializeTimeOffTypes() {
    await this.store._compose([
      endpoints.TIME_OFF.TYPES.ALL
    ]);

    this.selectedTimeOffTypes = this.store._getAll(
      types.TIME_OFF.TYPE,
      type => _.map(this.policy.typePolicies, 'type.id').includes(type.id),
      TimeOffType
    );

    this.timeOffTypes = this.store._getAll(types.TIME_OFF.TYPE, TimeOffType);
  }

  @action async initializeEmployees() {
    await this.store._compose([
      endpoints.TIME_OFF.POLICIES.ELIGIBLE_EMPLOYEES.with(this.policy.id)
    ]);

    this.selectedEmployees = this.store._getAll(
      types.EMPLOYEE,
      employee => _.map(this.policy.employees, 'id').includes(employee.id),
      Employee
    );
    this.availableEmployees = this.store._getAll(types.EMPLOYEE, Employee);
  }

  @action async initializeEmailTemplate() {
    await this.store._compose([
      endpoints.COMPANY_EMAIL_TEMPLATES
    ]);

    this.welcomeEmailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'pto_policy_invitation'});
  }

  @action updateHoursPerWorkday(value) {
    this.hoursPerWorkday = value;
    this.policy.workDayLength = value * 3600;
    this.workDayLengthChanged = true;
  }

  @action toggleWorkWeekDay(day) {
    if (this.workdaySelected(day)) {
      _.pull(this.policy.workWeekDays, day);
    } else {
      this.policy.workWeekDays.push(day);
    }
  }

  @action toggleTimeOffType(type) {
    if (this.timeOffTypeSelected(type)) {
      _.remove(this.selectedTimeOffTypes, t => t.id === type.id);
    } else {
      this.selectedTimeOffTypes.push(type);
    }
  }

  @action updateSelectedEmployees(employees) {
    this.selectedEmployees.replace(employees);
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'details': return this.submitDetails();
      case 'policy_types': return this.submitTimeOffTypes();
      case 'employees': return this.submitEmployees();
      case 'time_off_rules': return this.submitTypeRules();
      case 'review': return this.submitReview();
      default:
        throw new Error(`Location ${location} is not supported`);
    }
  }

  @action async submitDetails() {
    this.workDayLengthChanged = false;

    return this._submitStep(['name', 'workDayLength', 'startMonth', 'startDay', 'workWeekDays']);
  }

  @action async submitReview() {
    const submit = await this._submitStep(['sendEmployeeWelcomeNotifications']);
    if (submit) {
      this.history.push('/policies');
    }
  }

  @action async submitTimeOffTypes() {
    this.policy.types = this.selectedTimeOffTypes;

    return this._submitStep(['types']);
  }

  @action async submitEmployees() {
    this.policy.employees = this.selectedEmployees;

    return this._submitStep(['employees']);
  }

  @action async submitTypeRules() {
    const {model, errors} = await this.store.patch(this.typePolicy);

    this.errors = errors;
    if (!model) return false;

    this.typePolicy.update(model);
    return true;
  }

  @action async _submitStep(props) {
    const policy = this.policy.pick(props);
    const {model, errors} = await this.store.patch(policy);

    this.errors = errors;
    if (!model) return false;

    this.policy.update(model);
    this.steps = new Steps(this._initializeSteps());
    return true;
  }

  @action initializeTypePolicy(typePolicyId) {
    this.typePolicy = new TimeOffTypePolicy(
      this.store._getSingle(types.TIME_OFF.TYPE_POLICY, {id: typePolicyId})
    );

    this.accrualType = this.typePolicy.accrualFrequency === 'unlimited' ? 'unlimited' : 'standard';
    this.typePolicy.balanceAdjustmentPolicy || new BalanceAdjustmentPolicy({type: 'AnnualResetPolicy'});
    this.typePolicy.anniversaryBonuses = this.typePolicy.sortedAnniversaryBonuses;

    this.initializeBalanceAdjustmentPolicy();
    this.initializeWaitingPeriod();
    this.initializeAssignees();
  }

  @action initializeWaitingPeriod() {
    this.waitingPeriodEnabled = this.typePolicy.waitingPeriod > 0;
  }

  @action initializeBalanceAdjustmentPolicy() {
    if (!this.typePolicy.balanceAdjustmentPolicy) {
      this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'AnnualResetPolicy'});
    }
    const {balanceAdjustmentPolicy} = this.typePolicy;

    switch (balanceAdjustmentPolicy.type) {
      case 'AnnualResetPolicy':
        this.carryoverType = 'annual_reset';
        break;
      case 'NoLimitPolicy':
        this.carryoverType = 'no_limit';
        break;
      case 'BalanceLimitPolicy':
        this.carryoverType = 'balance_limit';
        break;
      case 'CarryoverPolicy':
        this.carryoverType = balanceAdjustmentPolicy.limitDays ? 'capped_with_deadline' : 'capped';
        break;
      default:
        throw new Error(`Balance adjustment type ${balanceAdjustmentPolicy.type} is not supported`);
    }
  }


  @action initializeAssignees() {
    const users = this.store._getAll(types.USER).map(u => new User(u));
    this.availableAssignees = [
      {
        id: 'manager', type: 'manager', name: t('time_off.policy.edit.Manager')
      },
      {
        id: 'managers_manager', type: 'managers_manager', name: t("time_off.policy.edit.Manager's manager")
      },
      ...users.map(u => {
        return { id: u.id, type: 'user', user: u, name: u.name };
      })
    ];

    this.assignee = _.find(this.availableAssignees, a => {
      return (this.typePolicy.secondStageApprovalType === 'manager' && a.type === 'manager') ||
        (this.typePolicy.secondStageApprovalType === 'managers_manager' && a.type === 'managers_manager') ||
        (this.typePolicy.secondStageApprovalType === 'user' && a.id === this.typePolicy.secondStageApprovalAssigneeUser.id);
    });
  }

  @action selectAssignee(assignee) {
    this.typePolicy.secondStageApprovalType = assignee.type;
    this.typePolicy.secondStageApprovalAssigneeUser = assignee.user;
    this.assignee = assignee;
  }

  @action selectAccrualType(accrualType) {
    switch(accrualType) {
      case 'standard':
        this.accrualType = accrualType;
        if (this.typePolicy.accrualFrequency === 'unlimited') {
          this.typePolicy.accrualFrequency = 'annual';
          this.initializeBalanceAdjustmentPolicy();
        }
        this.typePolicy.baseAccrualRate = '';
        break;
      case 'unlimited':
        this.accrualType = accrualType;
        this.typePolicy.accrualFrequency = 'unlimited';
        this.typePolicy.balanceAdjustmentPolicy = null;
        this.typePolicy.anniversaryBonuses = [];
        this.typePolicy.baseAccrualRate = null;
        break;
      default:
        throw new Error(`Accrual type ${accrualType} is not supported`);
    }
  }

  @action selectAccrualFrequency(accrualFrequency) {
    switch(accrualFrequency) {
      case 'annual':
      case 'monthly':
      case 'semimonthly':
        this.typePolicy.accrualFrequency = accrualFrequency;
        this.typePolicy.accrualStartDate = null;
        break;
      case 'biweekly':
      case 'weekly':
        this.typePolicy.accrualFrequency = accrualFrequency;
        this.typePolicy.accrualStartDate = moment().toDate();
        break;
      default:
        throw new Error(`Accrual frequency ${accrualFrequency} is not supported`);
    }
  }

  @action selectCarryoverType(carryoverType) {
    // TODO: Ensure we throw an error if you set capped with deadline but don't set a limit
    switch(carryoverType) {
      case 'annual_reset':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'AnnualResetPolicy'});
        break;
      case 'no_limit':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'NoLimitPolicy'});
        break;
      case 'balance_limit':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'BalanceLimitPolicy', parameter: ''});
        break;
      case 'capped':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'CarryoverPolicy', parameter: ''});
        break;
      case 'capped_with_deadline':
        this.typePolicy.balanceAdjustmentPolicy = new BalanceAdjustmentPolicy({type: 'CarryoverPolicy', parameter: '', limitDays: ''});
        break;
      default:
        throw new Error(`Carryover type ${carryoverType} is not supported`);
    }
    this.carryoverType = carryoverType;
  }

  @action addAnniversaryBonus() {
    const newBonus = new AnniversaryBonus({anniversary: '', effectiveRate: ''});
    this.typePolicy.anniversaryBonuses.push(newBonus);
  }

  @action removeAnniversaryBonus(anniversaryBonus) {
    this.typePolicy.anniversaryBonuses.remove(anniversaryBonus);
  }

  @computed get daysForStartMonth() {
    return new Date(new Date().getFullYear(), this.policy.startMonth, 0).getDate();
  }

  @computed get sortedTimeOffTypes() {
    return _.orderBy(this.timeOffTypes, 'order');
  }

  _initializeSteps() {
    const steps = _.clone(initialSteps);

    if (!_.isEmpty(this.policy.typePolicies)) {
      steps.push(...this.policy.typePolicies.map(t => {
        return {
          name: t.type.name,
          translateName: false,
          subStepLocation: t.id,
          useCustomLayout: true,
          location: 'time_off_rules'
        };
      }));
    }

    steps.push(...finalSteps);
    return steps.map((s, index) => ({ ...s, index: index + 1 }));
  }

  workdaySelected(day) {
    return this.policy.workWeekDays.includes(day);
  }

  timeOffTypeSelected(type) {
    return _.find(this.selectedTimeOffTypes, { id: type.id });
  }
}

export default PolicyEditFlowState;
