import {action, computed, observable} from 'mobx';
import {endpoints, api, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import _ from 'lodash';
import {CompanyFile, CompanyFileFolder} from 'stores/company_files';

class CompanyFileListState {
  store;

  @observable errors = {};
  @observable companyFiles = [];
  @observable companyFileFolders = [];
  @observable loadingFiles = false;

  @observable currentFolder = null;

  @observable selectedCompanyFile = {};
  @observable reorderingFiles;
  @observable editCompanyFileModalOpen = false;
  @observable reorderFilesModalOpen = false;

  @observable selectedCompanyFileFolder = {};
  @observable reorderingFolders;

  @action async load() {
    this.store = new DomainStore();

    this.loadingFiles = true;
    await this.store._compose([
      endpoints.COMPANY_FILES.with(this.currentFolderId),
      endpoints.COMPANY_FILE_FOLDERS.ALL
    ]);

    this.companyFiles = this.store._getAll(types.COMPANY_FILE, CompanyFile);
    this.companyFileFolders = this.store._getAll(types.COMPANY_FILE_FOLDER, CompanyFileFolder);
    this.loadingFiles = false;
  }

  @action openReorderFilesModal() {
    this.reorderingFiles = _.map(this.companyFiles, file => new CompanyFile(file));
    this.reorderFilesModalOpen = true;
  }

  @action closeReorderFilesModal() {
    this.reorderFilesModalOpen = false;
  }

  @action openEditCompanyFileModal(companyFile) {
    this.selectedCompanyFile = new CompanyFile(companyFile);
    this.editCompanyFileModalOpen = true;
  }

  @action closeEditCompanyFileModal() {
    this.editCompanyFileModalOpen = false;
    this.errors = {};
  }

  @action openReorderFoldersModal() {
    this.reorderingFolders = _.map(this.companyFileFolders, folder => new CompanyFileFolder(folder));
  }

  @action async deleteCompanyFile() {
    await this.store.destroy(this.selectedCompanyFile);
    this.closeRemoveCompanyFileModal();
    await this.load();
  }

  @action async saveFileOrders() {
    const fileParams = {};
    this.reorderingFiles.forEach(file => fileParams[file['id']] = file['order']);

    await api.patch(endpoints.COMPANY_FILES.REORDER, {'company_files': fileParams, 'company_file_folder_id': this.currentFolderId});

    this.closeReorderFilesModal();
    await this.load();
  }

  @action async saveCompanyFile() {
    const {model, errors} = this.selectedCompanyFile.isNew ?
      await this.store.post(endpoints.COMPANY_FILES.ALL, types.COMPANY_FILE, this.selectedCompanyFile) :
      await this.store.patch(this.selectedCompanyFile);

    this.errors = errors;
    if(model){
      this.closeEditCompanyFileModal();
      this.closeMoveCompanyFileModal();
      await this.load();
    }
  }

  @action removeFile() {
    this.selectedCompanyFile.file = null;
  }

  @action mergeFile(file) {
    this.selectedCompanyFile.merge({file: file});
  }

  @action updateFile(files) {
    const file = _.head(files);
    file ? this.mergeFile(file) : this.removeFile();
  }

  @action async deleteCompanyFileFolder() {
    await this.store.destroy(this.selectedCompanyFileFolder);
    this.closeRemoveCompanyFileFolderModal();
    await this.load();
  }

  @action async saveFolderOrders() {
    const folderParams = {};
    this.reorderingFolders.forEach(folder => folderParams[folder['id']] = folder['order']);

    await api.patch(endpoints.COMPANY_FILE_FOLDERS.REORDER, {'company_file_folders': folderParams});

    this.closeReorderFoldersModal();
    await this.load();
  }

  @action async saveCompanyFileFolder() {
    const {model, errors} = this.selectedCompanyFileFolder.isNew ?
      await this.store.post(endpoints.COMPANY_FILE_FOLDERS.ALL, types.COMPANY_FILE_FOLDER, this.selectedCompanyFileFolder) :
      await this.store.patch(this.selectedCompanyFileFolder);

    this.errors = errors;
    if(model){
      this.closeEditCompanyFileFolderModal();
      await this.load();
    }
  }

  @action async selectFolder(model) {
    if (model._type === types.COMPANY_FILE) return;

    this.loadingFiles = true;
    this.currentFolder = model;
    await this.load();
  }

  @action goToRootFolder() {
    this.currentFolder = null;
    this.load();
  }

  @computed get sortedCompanyFiles() {
    return _.sortBy(this.companyFiles, 'order');
  }

  @computed get sortedCompanyFileFolders() {
    return _.sortBy(this.companyFileFolders, 'order');
  }

  @computed get entries() {
    const folders = (this.loadingFiles && this.currentFolder) || (!this.loadingFiles && !this.currentFolder) ? this.sortedCompanyFileFolders : [];

    return [...folders, ...this.sortedCompanyFiles];
  }

  @computed get currentFolderId() {
    if (!this.currentFolder) return null;

    return this.currentFolder.id;
  }

  customLinksFor(model) {
    const links = [];
    if (model._type === types.COMPANY_FILE) {
      links.push(
        {
          text: 'Edit',
          order: 0,
          action: () => this.openEditCompanyFileModal(model)
        },
        {
          text: 'Remove',
          order: 2,
          action: () => this.openRemoveCompanyFileModal(model)
        }
      );

      if (!_.isEmpty(this.companyFileFolders)) {
        links.push({
          order: 1,
          text: 'employees.profile.documents.Move to Folder...',
          action: () => this.openMoveCompanyFileModal(model)
        });
      }
    } else {
      links.push(
        {
          text: 'Edit',
          action: () => this.openEditCompanyFileFolderModal(model)
        },
        {
          text: 'Remove',
          action: () => this.openRemoveCompanyFileFolderModal(model)
        }
      );
    }

    return links;
  }
}

export default CompanyFileListState;
