import {computed, observable} from 'mobx';
import moment from 'moment';

class PayrollDatesState {
  Emoji;

  @observable payPeriod;

  receiveProps({payPeriod, Emoji}) {
    this.payPeriod = payPeriod;
    this.Emoji = Emoji;
  }

  @computed get cutoffWarningDateDaysFromNow() {
    return this.daysFromNow(this.payPeriod.cutoffWarningDate);
  }

  @computed get payDateDaysFromNow() {
    return this.daysFromNow(this.payPeriod.payDate);
  }

  daysFromNow(date) {
    date = moment(date);

    return date.diff(moment().startOf('day'), 'days');
  }

  @computed get cutoffWarningDateColour() {
    switch (this.cutoffWarningDateDaysFromNow) {
      case 2:
        return 'sun';
      case 1:
      case 0:
        return 'scarlet';
      default:
        return null;
    }
  }

  @computed get payPeriodToArray() {
    return [
      {
        dateName: 'Payroll Data Submission Cutoff',
        date: this.payPeriod.cutoffWarningDate,
        daysFromNow: this.cutoffWarningDateDaysFromNow,
        colour: this.cutoffWarningDateColour
      },
      {
        dateName: 'Next Pay Date',
        date: this.payPeriod.payDate,
        daysFromNow: this.payDateDaysFromNow
      }
    ];
  }
}

export default PayrollDatesState;
