import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormLayout, Select2, NumberInput} from 'components';
import {FormattedMessage} from 'react-intl';

const AnnualCarryoverForm = observer(({uiState}) => {
  const {typePolicy, carryoverType} = uiState;

  if (carryoverType !== 'annual_reset') return null;

  return (
    <FormLayout>
      <Select2
        label='time_off.policy.edit.Balance reset on'
        value={typePolicy.accrualStart}
        onChange={e => typePolicy.accrualStart = e.target.value}
      >
        <option value='policy_start_date'>{t('time_off.policy.edit.Policy start date')}</option>
        <option value='policy_holder_start_date'>{t("time_off.policy.edit.Employee's anniversary")}</option>
      </Select2>
    </FormLayout>
  );
});

const BalanceLimitCarryoverForm = observer(({uiState}) => {
  const {typePolicy, carryoverType, errors} = uiState;
  const {balanceAdjustmentPolicy} = typePolicy;

  if (carryoverType !== 'balance_limit') return null;

  return (
    <FormLayout>
      <NumberInput
        label='time_off.policy.edit.Maximum balance'
        value={balanceAdjustmentPolicy.parameter}
        onChange={e => balanceAdjustmentPolicy.parameter = e.target.value}
        connectedRight={
          <div className='InputGroup'>
            <span className='InputGroup-tag small'>{t('time_off.policy.edit.day(s)')}</span>
          </div>
        }
        errorMessage={_.get(errors, 'balanceAdjustmentPolicy.parameter')}
      />
      <Select2
        label='time_off.policy.edit.Balance reset on'
        value={typePolicy.accrualStart}
        onChange={e => typePolicy.accrualStart = e.target.value}
      >
        <option value='policy_start_date'>{t('time_off.policy.edit.Policy start date')}</option>
        <option value='policy_holder_start_date'>{t("time_off.policy.edit.Employee's anniversary")}</option>
      </Select2>
    </FormLayout>
  );
});

const CappedCarryoverForm = observer(({uiState}) => {
  const {typePolicy, carryoverType, errors} = uiState;

  if (carryoverType !== 'capped') return null;

  return (
    <FormLayout>
      <NumberInput
        label='time_off.policy.edit.Maximum carryover'
        value={typePolicy.balanceAdjustmentPolicy.parameter}
          onChange={e => typePolicy.balanceAdjustmentPolicy.parameter = e.target.value}
        connectedRight={
          <div className='InputGroup'>
            <span className='InputGroup-tag small'>{t('time_off.policy.edit.day(s)')}</span>
          </div>
        }
        errorMessage={_.get(errors, 'balanceAdjustmentPolicy.parameter')}
      />
      <Select2
        label='time_off.policy.edit.Balance reset on'
        value={typePolicy.accrualStart}
        onChange={e => typePolicy.accrualStart = e.target.value}
      >
        <option value='policy_start_date'>{t('time_off.policy.edit.Policy start date')}</option>
        <option value='policy_holder_start_date'>{t("time_off.policy.edit.Employee's anniversary")}</option>
      </Select2>
    </FormLayout>
  );
});

const CappedCarryoverWithDeadlineForm = observer(({uiState}) => {
  const {typePolicy, carryoverType, errors} = uiState;

  if (carryoverType !== 'capped_with_deadline') return null;

  return (
    <FormLayout>
      <div className='flex flex-col md-flex-row row-gap-2'>
        <div className='col col-12 md-col-6 md-pr1'>
          <NumberInput
            label='time_off.policy.edit.Maximum carryover'
            value={typePolicy.balanceAdjustmentPolicy.parameter}
            onChange={e => typePolicy.balanceAdjustmentPolicy.parameter = e.target.value}
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_off.policy.edit.day(s)')}</span>
              </div>
            }
            errorMessage={_.get(errors, 'balanceAdjustmentPolicy.parameter')}
          />
        </div>
        <div className='col col-12 md-col-6 md-pl1'>
          <NumberInput
            label='time_off.policy.edit.Carryover must be used within'
            value={typePolicy.balanceAdjustmentPolicy.limitDays}
            onChange={e => typePolicy.balanceAdjustmentPolicy.limitDays = e.target.value}
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_off.policy.edit.day(s)')}</span>
              </div>
            }
            errorMessage={_.get(errors, 'balanceAdjustmentPolicy.limitDays')}
          />
        </div>
      </div>
      <Select2
        label='time_off.policy.edit.Balance reset on'
        value={typePolicy.accrualStart}
        onChange={e => typePolicy.accrualStart = e.target.value}
      >
        <option value='policy_start_date'>{t('time_off.policy.edit.Policy start date')}</option>
        <option value='policy_holder_start_date'>{t("time_off.policy.edit.Employee's anniversary")}</option>
      </Select2>
    </FormLayout>
  );
});

const CarryoverForm = observer(({uiState}) => {
  const {typePolicy, carryoverType} = uiState;

  return (
    <div className='mt3'>
      <FormLayout>
        <div>
          <Select2
            value={carryoverType}
            label='time_off.policy.edit.Carryover type'
            onChange={e => uiState.selectCarryoverType(e.target.value)}
            >
            <option value='annual_reset'>{t('time_off.policy.edit.carryover.Annual Reset')}</option>
            <option value='no_limit'>{t('time_off.policy.edit.carryover.Unlimited Carryover')}</option>
            <option value='balance_limit'>{t('time_off.policy.edit.carryover.Balance Limit')}</option>
            <option value='capped'>{t('time_off.policy.edit.carryover.Capped Carryover')}</option>
            <option value='capped_with_deadline'>{t('time_off.policy.edit.carryover.Capped Carryover with a Deadline')}</option>
          </Select2>
          <div className='mt1 jumbo'>
            <FormattedMessage id={`time_off.policy.edit.carryover.explanation.${carryoverType}`} values={{type: typePolicy.type.name.toLowerCase()}}/>
          </div>
        </div>
        <AnnualCarryoverForm uiState={uiState}/>
        <BalanceLimitCarryoverForm uiState={uiState}/>
        <CappedCarryoverForm uiState={uiState}/>
        <CappedCarryoverWithDeadlineForm uiState={uiState}/>
      </FormLayout>
    </div>
  );
});

export default CarryoverForm;
