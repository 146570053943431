import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import TimeOffAccount from './TimeOffAccount';
import Holiday from 'stores/holidays/Holiday';
import {Employee} from 'stores/employees';
import _ from 'lodash';
import {TimeOffPolicy} from 'stores/time_off/index';

class EmployeeTimeOffStore extends DomainStore {
  async load(employeeId) {
    this.invalidate(types.TIME_OFF.REQUEST);
    await this._compose([
      endpoints.TIME_OFF.ACCOUNTS.with(employeeId),
      endpoints.TIME_OFF.POLICY.withEmployeeId(employeeId),
      endpoints.TIME_OFF.UPCOMING_HOLIDAYS.with(employeeId),
      endpoints.EMPLOYEE_DETAILS.with(employeeId)
    ]);
  }

  getPolicy() {
    const policy = this._getSingle(types.TIME_OFF.POLICY);
    return new TimeOffPolicy(policy);
  }

  getAccounts() {
    const timeOffAccounts = this._getAll(types.TIME_OFF.ACCOUNT).map(t => new TimeOffAccount(t));
    return _.orderBy(timeOffAccounts, 'timeOffType.order');
  }

  getUpcomingHolidays() {
    return _.orderBy(this._getAll(types.HOLIDAY).map(h => new Holiday(h)), 'date', 'asc');
  }

  getEmployeeDetails() {
    return new Employee(this._getSingle(types.EMPLOYEE_DETAILS));
  }
}

const singleton = new EmployeeTimeOffStore();

export default singleton;
