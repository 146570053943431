import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {calendarDate} from 'shared/tools';

class PayPeriodEmployeesState {
  store = new DomainStore();
  match;

  @observable employees;
  @observable notes;
  @observable payPeriod;
  @observable payNumber;

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    const id = _.get(this.match.params, 'period_id');

    await this.store._compose([
      endpoints.PAY_PERIOD.WITH_EMPLOYEES.with(id)
    ]);

    this.payPeriod = this.store._getSingle(types.PAY_PERIOD, {id});
  }

  @computed get calendarStartDate() {
    return calendarDate(this.payPeriod.periodStartDate);
  }

  @computed get calendarEndDate() {
    return calendarDate(this.payPeriod.periodEndDate);
  }

  @computed get calendarCutoffWarningDate() {
    return calendarDate(this.payPeriod.cutoffWarningDate);
  }

  @computed get calendarPayDate() {
    return calendarDate(this.payPeriod.payDate);
  }
}

export default PayPeriodEmployeesState;