import React from 'react';
import {observer} from 'mobx-react';
import {calendarDate} from 'shared/tools';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, PanelRadioButton, RadioButtonGroup, FormError, TimeInput, LabelHelper} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const HalfDayForm = observer(({uiState}) => {
  const {editDayOff} = uiState;

  if (editDayOff.unit !== 'half' || editDayOff.hasHalfDayHoliday) return null;

  return (
    <div>
      <FormLayout>
        <FormLayout.Group>
          <TimeInput
            label='employees.profile.time_off.request.custom_hours.Start Time'
            placeholder='employees.profile.time_off.request.custom_hours.START_TIME_PLACEHOLDER'
            initialValue={editDayOff.json.start_time}
            onFormat={({value, error}) => (!error && value !== editDayOff.json.start_time) && editDayOff.updateStartTime(value)}
          />
          <div>
            <LabelHelper label='employees.profile.time_off.request.custom_hours.End Time' translateLabel/>
            <div className='jumbo whitespace-pre-line pt1 pb1'>
              <span>{!_.isNull(editDayOff.hours) ? editDayOff.json.end_time : '--'}</span>
            </div>
          </div>
        </FormLayout.Group>
      </FormLayout>
      {editDayOff.showErrorMessage && <FormError message={editDayOff.errorMessage}/>}
    </div>
  );
});

const CustomForm = observer(({uiState}) => {
  const {editDayOff} = uiState;

  if (editDayOff.unit !== 'custom') return null;

  return (
    <div>
      <FormLayout>
        <FormLayout.Group>
          <TimeInput
            label='employees.profile.time_off.request.custom_hours.Start Time'
            placeholder='employees.profile.time_off.request.custom_hours.START_TIME_PLACEHOLDER'
            initialValue={editDayOff.json.start_time}
            onFormat={({value, error}) => (!error && value !== editDayOff.json.start_time) && editDayOff.updateStartTime(value)}
          />

          <TimeInput
            label='employees.profile.time_off.request.custom_hours.End Time'
            placeholder='employees.profile.time_off.request.custom_hours.END_TIME_PLACEHOLDER'
            initialValue={editDayOff.json.end_time}
            lowerBound={editDayOff.json.start_time}
            onFormat={({value, error}) => (!error && value !== editDayOff.json.end_time) && editDayOff.updateEndTime(value)}
          />
        </FormLayout.Group>
      </FormLayout>
      <div className='jumbo'>
        {!_.isNull(editDayOff.hours) && <FormattedMessage id='employees.profile.time_off.request.custom_hours.HOURS' values={{hours: editDayOff.hours, days: editDayOff.days}}/>}
      </div>
      {editDayOff.showErrorMessage && <FormError message={editDayOff.errorMessage}/>}
    </div>
  );
});

const DayOffModal = observer(({uiState}) => {
  const {editDayOff, dayOffModalOpen} = uiState;
  if (!dayOffModalOpen) return null;

  return (
    <Modal
      isOpen={dayOffModalOpen}
      title={calendarDate(editDayOff.date, {includeDayOfWeek: true})}
      size='md'
      onHide={() => uiState.closeDayOffModal()}
      fullscreenOnMobile
      translateTitle={false}
    >
      <FormLayout>
      <RadioButtonGroup
        className={'clearfix table'}
        name='unit'
        selectedValue={editDayOff.unit}
        onChange={(name, value) => editDayOff.updateUnit(value)}
      >
        <PanelRadioButton
          value='full'
          disabled={editDayOff.hasHalfDayHoliday}
          label={<span><FormattedMessage id='employees.profile.time_off.request.custom_hours.Full Day'/>&nbsp;<span className='jumbo'><FormattedMessage id='employees.profile.time_off.request.custom_hours.TYPE_HOURS' values={{hours: editDayOff.workDayLengthHours}}/></span></span>}
          translateLabel={false}
          description={<FormattedMessage id='employees.profile.time_off.request.custom_hours.FULL_DAY_EXPLANATION' values={{hours: editDayOff.workDayLengthHours}}/>}
          translateDescription={false}
        />
        <PanelRadioButton
          value='half'
          label={<span><FormattedMessage id='employees.profile.time_off.request.custom_hours.Half Day'/>&nbsp;<span className='jumbo'><FormattedMessage id='employees.profile.time_off.request.custom_hours.TYPE_HOURS' values={{hours: editDayOff.workDayLengthHours/2}}/></span></span>}
          translateLabel={false}
          description={<FormattedMessage id='employees.profile.time_off.request.custom_hours.HALF_DAY_EXPLANATION' values={{hours: editDayOff.workDayLengthHours/2}}/>}
          translateDescription={false}
        >
          <HalfDayForm uiState={uiState}/>
        </PanelRadioButton>
        <PanelRadioButton
          value='custom'
          label='employees.profile.time_off.request.custom_hours.Custom Hours'
          description='employees.profile.time_off.request.custom_hours.CUSTOM_HOURS_EXPLANATION'
        >
          <CustomForm uiState={uiState}/>
        </PanelRadioButton>
      </RadioButtonGroup>
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.updateCustomHours()}
        onCancel={() => uiState.closeDayOffModal()}
      />
    </Modal>
  );
});

export default DayOffModal;
