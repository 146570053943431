import _ from 'lodash';
import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {auth} from 'shared/core';
import IndeedIntegrationStatus from './IndeedIntegrationStatus';

class Image extends DomainObject {
  @observable fileName;
  @observable fileSize;
  @observable contentType;
  @observable key;
  @observable label;

  @computed get uploadedUrl() {
    return `${this.label}?policy=${auth.fileStack.uploadPolicy.policy}&signature=${auth.fileStack.uploadPolicy.signature}`;
  }
}

class JobSite extends DomainObject {
  @observable website;
  @observable urlSlug;
  @observable socialSharingImage;
  @observable indeedEnabled;
  @observable indeedApplyEnabled;
  @observable indeedCompanyName;
  @observable indeedAccountEmail;
  @observable indeedIntegrationStatus;

  @computed get permalink() {
    return this.link('permalink');
  }

  @computed get hasPermalink() {
    return this.hasLink('permalink');
  }

  @computed get integrationLink() {
    return `https://api.collage.co/v1/positions/${this.urlSlug}`;
  }

  @computed get hasSocialSharingImage() {
    return !!this.socialSharingImage && !!this.socialSharingImage.fileName;
  }

  @computed get hasUploadedSocialSharingImage() {
    return this.hasSocialSharingImage && !this.socialSharingImageUrl;
  }

  @computed get socialSharingImageUrl() {
    return _.get(this.socialSharingImage, 'links.url.href');
  }

  @action updateSocialSharingImage(json) {
    return this.merge({ socialSharingImage: json });
  }

  @action merge(other) {
    super.merge(other, {
      indeedIntegrationStatus: IndeedIntegrationStatus
    });
    if (other.socialSharingImage) {
      this.socialSharingImage = new Image(other.socialSharingImage);
    }
  }
}

export default JobSite;
