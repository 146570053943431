import _ from 'lodash';
import {DomainObject, oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {timestampDate} from 'shared/tools';
import Position from './Position';

class IndeedIntegrationStatus extends DomainObject {
  @observable lastSyncedAt;
  @observable status;
  @observable positions;

  @action merge(other) {
    super.merge(other, {
      positions: [Position],
      status: oneOf(['pending', 'active']),
      _dates: ['lastSyncedAt']
    });
  }

  @computed get lastSyncedAtView() {
    return timestampDate(this.lastSyncedAt);
  }

  @computed get isActive() {
    return this.status === 'active';
  }
}

export default IndeedIntegrationStatus;
