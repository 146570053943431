import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'shared/core';

const PanelRadioButton = ({value, label, description, className, trait, children, disabled, translateLabel, translateDescription}, context) => {
  const {name, selectedValue, onChange} = context.radioButtonGroup;
  const isActive = selectedValue === value;

  return (
    <div
      className={`Radio-button--bordered ${isActive ? 'active' : 'clickable'} ${disabled ? 'disabled' : ''}`}
      onClick={() => !disabled && !isActive && onChange(name, value)}
    >
      <label className={`Radio-button block mt2 ${className}`}>
        <input
          type='radio'
          name={name}
          value={value}
          onChange={() => onChange(name, value)}
          checked={isActive}
          disabled={disabled}
        />
        <span className={`Radio-button-circle ${trait}`}></span>
        <span className={`h4 align-top ${disabled ? 'waterloo' : ''}`}>
          <span className='medium'>{translateLabel && label ? t(label) : label}</span>
          {description && (
            <div className='mt1 waterloo' style={{marginLeft: '28.8px'}}>
              {translateDescription && description ? t(description) : description}
            </div>
          )}
        </span>
      </label>
      {isActive && !!children && <div className='mt2' style={{marginLeft: '28.8px'}}>
        {children}
      </div>}
    </div>
  );
};

PanelRadioButton.propTypes = {
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  trait: PropTypes.oneOf([
    'default',
    'error'
  ]),
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.node,
  translateLabel: PropTypes.bool,
  translateDescription: PropTypes.bool,
  children: PropTypes.node,
};

PanelRadioButton.defaultProps = {
  translateLabel: true,
  translateDescription: true,
  trait: 'default'
};

PanelRadioButton.contextTypes = {
  radioButtonGroup: PropTypes.object
};

export default PanelRadioButton;
