import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveContext, Panel, Table, DropdownFilter, DatePicker, Pagination} from 'components';
import {FormattedMessage} from 'react-intl';
import {TimeOffTransaction} from 'stores/time_off';
import {t, types, endpoints} from 'shared/core';
import {calendarDate, dateToJson} from 'shared/tools';
import _ from 'lodash';
import useModal from 'shared/hooks/useModal';
import EditManualAdjustmentModal from 'containers/employees/profile/components/time_off/components/EditManualAdjustmentModal';
import ManualAdjustmentTransaction from 'stores/time_off/ManualAdjustmentTransaction';

const TransactionAmount = observer(({model}) => {
  if (model.hasAmount) return <span>{model.amount}</span>;

  return <FormattedMessage id='N/A'/>;
});

const RunningTotal = observer(({model}) => {
  if (_.isNil(model.balance)) return null;

  return (
    <div className='waterloo' style={{fontStyle: 'italic'}}>
      <FormattedMessage id='employees.profile.time_off.transactions.TOTAL' values={{balance: <span className={`${model.balance < 0 ? 'puce' : ''}`}>{model.balance}</span>}}/>
    </div>
  );
});

const TransactionAmountWithRunningTotal = observer(({model}) => {
  return (
    <div>
      <div className='pb1'><TransactionAmount model={model}/></div>
      <RunningTotal model={model}/>
    </div>
  );
});

const AccrualPeriodDescription = observer(({model}) => {
  if (!model.accrualPeriodStartDate || !model.accrualPeriodEndDate) return null;

  return (
    <div className='jumbo'>
      <FormattedMessage
        id='employees.profile.time_off.transactions.ACCRUAL_PERIOD'
        values={{
          start_date: calendarDate(model.accrualPeriodStartDate),
          end_date: calendarDate(model.accrualPeriodEndDate)
        }}
      />
    </div>
  );
});

const TimeOffDescription = observer(({model, uiState}) => {
  switch (model.transactionType) {
    case 'accrual_transaction':
      return (
        <div>
          <div><FormattedMessage id={`employees.profile.time_off.transactions.${model.transactionType}`}/></div>
          <AccrualPeriodDescription model={model}/>
        </div>
      );
    case 'manual_adjustment_transaction':
      return (
        <div>
          <div><FormattedMessage id={`employees.profile.time_off.transactions.${model.transactionType}`}/></div>
          {model.createdByUser && <div className='jumbo'>
            <FormattedMessage id='employees.profile.time_off.transactions.CREATED_BY' values={{user: model.createdByUser.name}}/>
          </div>}
          <div className='jumbo'>{model.notes}</div>
        </div>
      );
    case 'absence_transaction':
      return (
        <div>
          <div><FormattedMessage id={`employees.profile.time_off.transactions.${model.transactionType}`}/></div>
          {model.timeOffRequest && <div className='jumbo'>{model.timeOffRequest.datesView}</div>}
        </div>
      );
    case 'balance_reset_transaction':
      if (model.reason === 'reactivation') {
        return (
          <div>
            <div><FormattedMessage id={`employees.profile.time_off.transactions.reactivation_reset_transaction`}/></div>
            <div className='jumbo'><FormattedMessage id='employees.profile.time_off.transactions.Reason: employee reactivation'/></div>
          </div>
        );
      }
      return <FormattedMessage id={`employees.profile.time_off.transactions.annual_balance_reset_transaction`}/>;
    case 'carry_over_transaction':
      const description = model.amount > 0 ? 'carry_over_transaction_positive' : 'carry_over_transaction_negative';
      return <FormattedMessage id={`employees.profile.time_off.transactions.${description}`}/>;
    default:
      return <FormattedMessage id={`employees.profile.time_off.transactions.${model.transactionType}`}/>;
  }
});

const PostedAtInEST = observer(({model, uiState}) => {
  const moment = uiState.modules['moment-timezone'].default;

  return calendarDate(
    moment.tz(model.postedAt, 'America/Toronto')
  );
});

const COLUMNS = [
  {
    header: 'employees.profile.time_off.Date',
    component: PostedAtInEST,
    width: 4
  },
  {
    header: 'employees.profile.time_off.Description',
    component: TimeOffDescription,
    width: 5
  },
  {
    header: 'employees.profile.time_off.Days (+/-)',
    render: model => <TransactionAmountWithRunningTotal model={model}/>,
    width: 2,
    className: 'right-align'
  }
];

const LOCALIZATION = {
  emptyState: 'employees.profile.time_off.No transactions to display',
  removeModal: {
    header: 'employees.profile.time_off.Delete Manual Adjustment',
    body: 'employees.profile.time_off.Are you sure you want to delete this manual adjustment?'
  }
};

const TableBalance = observer(({balance}) => {
  if (!balance) return (
    <div>
      <span className='h1 medium'>{'∞'}</span>
      <span className='ml1 jumbo'><FormattedMessage id='employees.profile.time_off.Unlimited'/></span>
    </div>
  );

  return (
    <div>
      <span className='h1 medium'>{balance}</span>
      <span className='ml1 jumbo'><FormattedMessage id='employees.profile.time_off.days available'/></span>
    </div>
  );
});

const FilterRow = observer(({uiState, models, ...rest}) => {
  const {transactionsForecastDate, accountFilterOptions} = uiState;

  return (
    <div className='TransactionsTableFilterHeader'>
      <div className='TransactionsTableFilterRow'>
        <div className='flex justify-content-between'>
          <Panel.Header title={t('employees.profile.time_off.Balance Breakdown')}/>
          <DropdownFilter options={accountFilterOptions} attr='account_id' {...rest}/>
        </div>
      </div>
      <div className='TransactionsTableFilterRow py2'>
        <div className='flex justify-content-between'>
          {!_.isEmpty(models) && <TableBalance balance={uiState.transactionListLastestBalance(models)}/>}
          <div className='flex align-items-middle'>
            <div className='mr1'><FormattedMessage id='employees.profile.time_off.As of:'/></div>
            <DatePicker
              value={transactionsForecastDate}
              onChange={date => uiState.updateTransactionsForecastDate(date)}
              options={{
                startDate: '0'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const TransactionList = observer(({uiState}) => {
  const {transactionsForecastDate} = uiState;
  const editManualAdjustmentModalOpen = useModal(
    uiState, 'EditManualAdjustmentModal', 'editingManualAdjustment', ManualAdjustmentTransaction, 'errors'
  );

  return (
    <Panel>
      <InteractiveContext
        uiState={uiState}
        onMount={agent => uiState.setTransactionsInteractiveAgent(agent)}
        searchable={false}
        proxy={{
          type: 'async',
          modelType: types.TIME_OFF.TRANSACTION,
          model: TimeOffTransaction,
          endpoint: endpoints.TIME_OFF.TRANSACTIONS.with(dateToJson(transactionsForecastDate))
        }}
        initialFilter={{
          account_id: uiState.accountFilterOptions[0].id
        }}
      >
        <FilterRow uiState={uiState}/>
        <Table
          columns={COLUMNS}
          localization={LOCALIZATION}
          onRemove={model => uiState.deleteManualAdjustment(model)}
          onEdit={model => uiState.openEditManualAdjustmentModal(model)}
        />
        <Pagination/>
      </InteractiveContext>
      <EditManualAdjustmentModal isOpen={editManualAdjustmentModalOpen} uiState={uiState}/>
    </Panel>
  );
});

export default TransactionList;
