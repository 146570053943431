import React from 'react';
import {observer} from 'mobx-react';
import {withState, endpoints, types, t} from 'shared/core';
import {PendingRequestsListState} from '../state';
import {Layout, InteractiveTable, EmployeeNameWithAvatar, DropdownFilter, AvatarList} from 'components';
import {TimeOffRequest} from 'stores/time_off';
import {dateFormatter} from 'shared/tools';
import TimeOffSubnav from 'containers/time_off/TimeOffSubnav';

const ApprovalStage = observer(({model}) => {
  if (!model.totalApprovalStages) return null;

  return(
    <span>{`${model.currentStage} of ${model.totalApprovalStages}`}</span>
  );
});

const COLUMNS = [
  {
    header: 'Name',
    render: model => <EmployeeNameWithAvatar employee={model.employee} />,
    width: 2,
    className: 'xs-col-11'
  },
  {
    header: 'time_off.requests.Type',
    attribute: 'timeOffType.name',
    width: 2,
    className: 'xs-hide'
  },
  {
    header: 'time_off.requests.Requested Dates',
    attribute: 'datesView',
    width: 3,
    className: 'xs-hide'
  },
  {
    header: 'time_off.requests.Days',
    attribute: 'amount',
    width: 1,
    className: 'xs-hide'
  },
  {
    header: 'time_off.requests.Stage',
    render: model => <ApprovalStage model={model}/>,
    width: 1,
    className: 'xs-hide'
  },
  {
    header: 'time_off.requests.Assigned To',
    render: model => <AvatarList employees={model.currentlyAssignedUsers}/>,
    width: 1,
    className: 'xs-hide'
  },
  {
    header: 'time_off.requests.Submitted',
    render: model => dateFormatter(model.createdAt),
    width: 1,
    className: 'md-and-below-hide'
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'employees.profile.time_off.Delete Time Off Request',
    subHeader: 'employees.profile.time_off.Are you sure you want to delete this time off request?',
    body: 'employees.profile.time_off.The requested time off will be refunded.'
  },
  emptyState: 'employees.profile.time_off.No requests to display',
  removeModel: 'employees.profile.time_off.Delete'
};

function scopeFilters() {
  return [
    {
      scope: 'late',
      display: t('time_off.requests.scopes.Late')
    }
  ];
}

const PendingRequestsList = observer(({uiState, scope}) => {
  const {statusOptions} = uiState;

  return (
    <div>
      <TimeOffSubnav scope={scope} />
      <Layout>
        <InteractiveTable
          saveStateToUrl
          sortable={false}
          searchable={false}
          title={t('time_off.requests.Pending Requests')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          onRemove={request => uiState.deleteRequest(request)}
          onEdit={request => uiState.redirectToEdit(request)}
          onRowClicked={request => uiState.viewRequest(request)}
          proxy={{
            type: 'async',
            modelType: types.TIME_OFF.REQUEST,
            model: TimeOffRequest,
            endpoint: endpoints.TIME_OFF.PENDING_REQUESTS
          }}
          scopeOptions={scopeFilters()}
        >
          <DropdownFilter attr='show' defaultFilter={1} options={statusOptions} />
        </InteractiveTable>
      </Layout>
    </div>
  );
});

export default withState(PendingRequestsList, PendingRequestsListState);
