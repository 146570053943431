import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, EmployeeTableSelector} from 'components';

const Employees = observer(({uiState}) => {
  const {availableEmployees, selectedEmployees, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_off.policy.edit.Add Employees')}
        description={t('time_off.policy.edit.Choose the employees who should be enrolled under this time off policy.')}
      />
      <EmployeeTableSelector
        employees={availableEmployees}
        selectedEmployees={selectedEmployees}
        onChange={employees => uiState.updateSelectedEmployees(employees)}
        errorMessage={errors.employees}
      />
    </div>
  );
});

export default Employees;

