import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {withState, auth, t} from 'shared/core';
import {PendingRequestDetailsState} from '../state';
import {Display, Layout, Panel, List, Avatar, Button, FormLayout, QuickTip, TextArea, Accordion, ActionLink, EmployeeNameWithAvatar, AttachedDocumentDownload} from 'components';
import {dateSpan} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import CustomHours from './CustomHours';
import Usage from './Usage';
import Table from 'components/tables/components/Table';

const LOCALIZATION = {
  emptyState: 'time_off.requests.Nobody else is out',
};

const MAX_DAYS_OFF_ITEMS = 3;

const ActionForm  = observer(({uiState}) => {
  const {request} = uiState;

  return (
    <div className='mt2'>
      <FormLayout>
        <TextArea
          value={request.actionNotes}
          onChange={e => request.merge({actionNotes: e.target.value})}
          label='time_off.requests.Notes'
          placeholder='time_off.requests.Optional response notes'
        />
      </FormLayout>
    </div>
  );
});

const NegativeBalances = observer(({request, showNegativeBalanceWarning}) => {
  if (!showNegativeBalanceWarning) return null;

  return (
    <div className='my2'>
      <QuickTip
        trait='caution'
        header={<FormattedMessage id='time_off.requests.NEGATIVE_BALANCES_HEADER'/>}
        paragraphs={[(auth.employee.id !== request.employee.id) ?
          <FormattedMessage id='time_off.requests.NEGATIVE_BALANCES_MESSAGE' values={{name: request.employee.name}}/> :
          <FormattedMessage id='time_off.requests.NEGATIVE_BALANCES_EMPLOYEE_MESSAGE'/>
        ]}
      />
    </div>
  );
});

const ActionButtons = observer(({uiState}) => {
  return (
    <Button.Group>
      <Button onClick={() => uiState.approveRequest()}>
        <FormattedMessage id='time_off.requests.Approve' />
      </Button>
      <Button trait='default' onClick={() => uiState.rejectRequest()}>
        <FormattedMessage id='time_off.requests.Reject' />
      </Button>
    </Button.Group>
  );
});

const NotesBy = observer(({notes, user}) => {
  if (!notes) return null;

  return (
    <div className='my3'>
      <div className='medium mb1'>
        <FormattedMessage id='time_off.requests.NOTE_BY' values={{user: user.name}} />
      </div>
      <div className='table-cell align-top pr2'>
        <Avatar size='sm' employee={user} />
      </div>
      <div className='table-cell align-middle whitespace-pre-line'>
        {notes}
      </div>
    </div>
  );
});

const Attachments = observer(({attachments}) => {
  if (_.isEmpty(attachments)) return null;

  return (
    <div className='my3'>
      <div className='medium mb1'>
        <FormattedMessage id='time_off.requests.Attachments'/>
      </div>
      {attachments.map(attachment => <AttachedDocumentDownload key={attachment.id} doc={attachment}/>)}
    </div>
  );
});

const DelegatedBy = observer(({user}) => {
  if (!user) return null;

  return (
    <div className='mt1 mb3'>
      <div className='table-cell align-middle whitespace-pre-line medium mr'>
        <FormattedMessage id='time_off.requests.DELEGATED_BY' />
      </div>
      <div className='table-cell align-top pl2'>
        <EmployeeNameWithAvatar employee={user}/>
      </div>
    </div>
  );
});

const ConcurrentRequestDetails = observer(({timeOffRequest}) => {
  const {employee} = timeOffRequest;
  return (
    <div>
      <span>{`${employee.firstName} ${employee.lastName}`}</span>
      <div className='table'>
        <span className='col-8 table-cell left-align waterloo'>
          <div>{timeOffRequest.timeOffType.name}</div>
          <div>{`${dateSpan(timeOffRequest.startDate, timeOffRequest.endDate)}`}</div>
        </span>
        <span className={`col-4 table-cell right-align align-middle ${timeOffRequest.status === 'approved' ? 'meadow' : 'sun'}`}>{_.capitalize(timeOffRequest.status)}</span>
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 2,
    render: model => <Avatar size='sm' employee={model.employee} />
  },
  {
    width: 10,
    render: model => <ConcurrentRequestDetails timeOffRequest={model} />
  }
];

const PendingRequestDetailsWithState = observer(({uiState}) => {
  const {request, daysOff, summaryItems, showNegativeBalanceWarning} = uiState;
  const type = request.timeOffType.name;

  return (
    <div>
      <Display
        title={request.employee.name}
        backPath='/pending_requests'
        backTitle={t('time_off.requests.Pending Requests')}
        nested
      />
      <Layout>
        <Layout.Section>
          <Panel>
            <Panel.Header
              title={request.currentlyDelegatedByUser ?
                t('time_off.requests.TYPE_REQUEST_DELEGATED', {type}) :
                t('time_off.requests.TYPE_REQUEST', {type})
              } />
            <DelegatedBy user={request.currentlyDelegatedByUser}/>
            <Panel.Subheader title={request.datesView}/>
            <NegativeBalances request={request} showNegativeBalanceWarning={showNegativeBalanceWarning}/>
            <List items={summaryItems}/>
            <Panel.Separator className='mt3'/>
            <Panel.Subheader title={t('time_off.requests.Days Off')}/>
            <CustomHours models={daysOff.slice(0, MAX_DAYS_OFF_ITEMS)}/>
            <div className='mb2'>
              <Accordion active={uiState.expanded}>
                <div className='mt1'>
                  <CustomHours models={daysOff.slice(MAX_DAYS_OFF_ITEMS)}/>
                </div>
              </Accordion>
            </div>
            {daysOff.length > 3 && <ActionLink
              onClick={() => uiState.expanded = !uiState.expanded}
              className='h6 waterloo'>
                {uiState.expandedText}
            </ActionLink>}
            <Panel.Separator className='mt3'/>
            <NotesBy user={request.createdByUser} notes={request.notes}/>
            <Attachments attachments={request.attachments}/>
            <NotesBy user={request.firstStageActionedByUser} notes={request.firstStageNotes} />
            <NotesBy user={request.secondStageActionedByUser} notes={request.secondStageNotes} />
            <ActionForm uiState={uiState} />
          </Panel>
          <ActionButtons uiState={uiState}/>
        </Layout.Section>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <Usage uiState={uiState}/>
            <Panel>
              <Panel.Header title={t('time_off.requests.Who else is out?')} />
              <Table
                localization={LOCALIZATION}
                columns={COLUMNS}
                models={uiState.concurrentRequests}
                showHeaders={false}
                showLinks={false}
                uiState={uiState}
              />
            </Panel>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(PendingRequestDetailsWithState, PendingRequestDetailsState);
