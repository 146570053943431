import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Spinner, Table} from 'components';
import {calendarDate} from 'shared/tools';
import {t} from 'shared/core';
import EmptyState from './RequestEmptyState';

const COLUMNS = [
  {
    header: 'employees.profile.time_off.request.forecast.Balance',
    attribute: 'type',
    width: 4
  },
  {
    header: 'employees.profile.time_off.request.forecast.Before',
    render: model => <span className={`${model.before_is_negative ? 'scarlet' : ''}`}>{model.before}</span>,
    width: 4
  },
  {
    header: 'employees.profile.time_off.request.forecast.After',
    render: model => <span className={`${model.after_is_negative ? 'scarlet' : ''}`}>{model.after}</span>,
    width: 4
  }
];

const Usage = observer(({uiState}) => {
  const {request, forecastingAccount, usageItems} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.time_off.request.USAGE', {timeOffType: request.timeOffType.name})}/>
      {request.hasValidDates && <div>
        <Panel.Subheader title={t('employees.profile.time_off.request.AS_OF', {date: calendarDate(request.startDate)})}/>
        {!forecastingAccount &&
          <Table
            showLinks={false}
            columns={COLUMNS}
            models={usageItems}
          />
        }
        {forecastingAccount && <Spinner/>}
      </div>}
      {!request.hasValidDates && <EmptyState message='employees.profile.time_off.request.Enter a valid start date and end date to see usage information.'/>}
    </Panel>
  );
});

export default Usage;
