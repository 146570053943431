import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, NumberInput, RadioButtonGroup, RadioButton} from 'components';
import {FormattedMessage} from 'react-intl';

const WaitingPeriodForm = observer(({uiState}) => {
  const {waitingPeriodEnabled, typePolicy} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('time_off.policy.edit.WAITING_PERIOD', {type: typePolicy.type.name})}/>
      <FormLayout>
        <div className='waterloo'>
          <FormattedMessage id='time_off.policy.edit.WAITING_PERIOD_EXPLANATION' values={{type: typePolicy.type.name.toLowerCase()}}/>
        </div>
        <RadioButtonGroup
          name='waitingPeriodEnabled'
          selectedValue={waitingPeriodEnabled}
          onChange={(name, value) => uiState.waitingPeriodEnabled = value}
        >
          <RadioButton className='table-cell pr2' value={false}><FormattedMessage id='time_off.policy.edit.No'/></RadioButton>
          <RadioButton className='table-cell pl2' value={true}><FormattedMessage id='time_off.policy.edit.Yes'/></RadioButton>
        </RadioButtonGroup>
        {waitingPeriodEnabled && <NumberInput
          value={typePolicy.waitPeriod}
          onChange={(e) => typePolicy.waitPeriod = e.target.value}
          min={'1'}
          connectedRight={
            <div className='InputGroup'>
              <span className='InputGroup-tag small'>{t('time_off.policy.edit.day(s)')}</span>
            </div>
          }
        />}
      </FormLayout>
    </Panel>
  );
});

export default WaitingPeriodForm;
