import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Input, Button, FormLayout, Panel, QuickTip, ActionLinks} from 'components';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import DeactivateIndeedModal from './DeactivateIndeedModal';
import EditIndeedModal from './EditIndeedModal';
import useModal from 'shared/hooks/useModal';
import _ from 'lodash';

const Status = observer(({jobSite}) => {
  const {indeedIntegrationStatus} = jobSite;

  return (
    <div className='flex flex-wrap'>
      <div className='mb1 mr3'>
        <FormattedMessage id='recruiting.job_site.Status'/>
        <div className={`flex align-items-middle ${indeedIntegrationStatus.isActive ? 'grass' : 'sun'}`}>
          <FormattedMessage id={`recruiting.job_site.indeed_integration_statuses.${indeedIntegrationStatus.status}`}/>
          <i className='material-icons align-top ml1 waterloo'>{indeedIntegrationStatus.isActive ? 'language' : 'sync'}</i>
        </div>
      </div>
      {indeedIntegrationStatus.isActive && <div className='mb1 mr3'>
        <FormattedMessage id='recruiting.job_site.Last Synced'/>
        <div className='jumbo'>{indeedIntegrationStatus.lastSyncedAtView}</div>
      </div>}
      <div className='mb1 mr3'>
        <FormattedMessage id='recruiting.job_site.Indeed Company Name'/>
        <div className='jumbo'>{jobSite.indeedCompanyName}</div>
      </div>
      <div className='mb2'>
        <FormattedMessage id='recruiting.job_site.Indeed Account Email'/>
        <div className='jumbo'>{jobSite.indeedAccountEmail}</div>
      </div>
    </div>
  );
});

const InvalidIndeedLocation = observer(({invalidLocation}) => {
  return (
    <div>
      <div>
        <FormattedMessage id='recruiting.job_site.invalid_locations.LOCATION' values={{name: invalidLocation.location.name}}/>
      </div>
      <div>
        <FormattedMessage
          id='recruiting.job_site.invalid_locations.POSITIONS'
          values={{
            title: invalidLocation.positions[0].title,
            count: invalidLocation.positions.length - 1
          }}
        />
      </div>
      <div>
        <FormattedMessage id='recruiting.job_site.invalid_locations.MISSING_FIELDS' values={{fields: invalidLocation.missingFieldsView}}/>
      </div>
    </div>
  );
});

const SyncedPositionsList = observer(({syncedPositions}) => {
  if (_.isEmpty(syncedPositions)) return <FormattedMessage id='recruiting.job_site.No positions were sent to Indeed during the last sync'/>;

  return (
    <React.Fragment>
      <div className='my1 jumbo'><FormattedMessage id='recruiting.job_site.The following positions were received by Indeed during the last sync:'/></div>
      {syncedPositions.map(position => <div className='flex space-x'>
        <a href={`/recruiting/positions/${position.id}/funnel`}>{position.title}</a>
        <span>{'-'}</span>
        <span>{position.location.name}</span>
        <span>{'-'}</span>
        <span><FormattedMessage id={position.employmentType}/></span>
      </div>)}
      <div className='mt1 jumbo'>
        <FormattedMessage id='recruiting.job_site.SYNC_DISCLAIMER'/>&nbsp;
        <a href='https://support.collage.co/en/articles/1740931-indeed-integration-and-faq#h_b23664a9ea' target='_blank'>
          <FormattedMessage id='recruiting.job_site.Learn more'/>
        </a>
      </div>
    </React.Fragment>
  );
});

const IndeedStatusInfo = observer(({uiState}) => {
  const {jobSite, invalidLocations} = uiState;
  const {indeedIntegrationStatus} = jobSite;

  return (
    <div>
      <Status jobSite={jobSite}/>
      {invalidLocations.length > 0 && <div className='mb2'>
        <QuickTip
          trait='caution'
          header={<FormattedMessage id='recruiting.job_site.invalid_locations.Warning: The following positions/locations may not be visible on Indeed'/>}
          paragraphs={invalidLocations.map((invalidLocation) => <InvalidIndeedLocation invalidLocation={invalidLocation}/>)}
        />
      </div>}
      {indeedIntegrationStatus.isActive && <div className='mb2'>
        <FormattedMessage id='recruiting.job_site.Synced Positions'/>
        <SyncedPositionsList syncedPositions={indeedIntegrationStatus.syncedPositions}/>
      </div>}
    </div>
  );
});

const IndeedForm = observer(({uiState}) => {
  const {errors, jobSite} = uiState;

  return (
    <div>
      <div className='jumbo mb2'><FormattedMessage id='recruiting.job_site.INDEED_INTEGRATION_SETTINGS_DESCRIPTION'/></div>
      <FormLayout>
        <Input
          label='recruiting.job_site.Indeed Company Name'
          value={jobSite.indeedCompanyName}
          errorMessage={errors.indeedCompanyName}
          onChange={e => jobSite.indeedCompanyName = e.target.value}
        />
        <Input
          label='recruiting.job_site.Indeed Account Email'
          value={jobSite.indeedAccountEmail}
          errorMessage={errors.indeedAccountEmail}
          onChange={e => jobSite.indeedAccountEmail = e.target.value}
        />
      </FormLayout>
      <div className='mt3 right-align'>
        <Button trait='primary' onClick={() => uiState.saveIndeedForm()}>
          <FormattedMessage id={uiState.indeedFormSaveCaption}/>
        </Button>
      </div>
    </div>
  );
});

const IndeedIntegrationPanel = observer(({uiState}) => {
  const {jobSite, showIndeedStatusInfo} = uiState;
  const editIndeedModalOpen = useModal(uiState, 'EditIndeedModal');

  return (
    <Panel>
      <Panel.Header title={t('recruiting.job_site.Indeed Integration')}>
        {showIndeedStatusInfo && <div className='flex full-width justify-content-end'>
          <ActionLinks
            model={jobSite}
            customLinks={[
              {
                text: 'recruiting.job_site.Edit Account Info',
                action: () => uiState.openEditIndeedModal()
              },
              {
                text: 'recruiting.job_site.Deactivate',
                action: () => uiState.openDeactivateIndeedModal()
              }
            ]}
          />
        </div>}
      </Panel.Header>
      {showIndeedStatusInfo ? <IndeedStatusInfo uiState={uiState}/> : <IndeedForm uiState={uiState}/>}
      <DeactivateIndeedModal uiState={uiState}/>
      <EditIndeedModal modalOpen={editIndeedModalOpen} uiState={uiState}/>
    </Panel>
  );
});

export default IndeedIntegrationPanel;
